import { defineComponent } from "vue"
import { StatusBarItem } from "./StatusBarItem"
import { StatusBarItem as Item, useStatusBarService } from "@maine-cat/service/statusbar"

function sortItem(a: Item, b: Item) {
  return ((a.order || 0) - (b.order || 0)) || a.id.localeCompare(b.id)
}

export const StatusBar = defineComponent({
  name: "StatusBar",
  setup() {
    const { items } = useStatusBarService()
    return () => {
      const left = items.filter(x => x.position === "left").sort(sortItem).reverse()
      const right = items.filter(x => x.position !== "left").sort(sortItem)

      return (
        <div class="StatusBar h-8 px-4 space-x-1 bg-zinc-600 dark:bg-zinc-600 flex items-stretch">
          {left.map(x => <StatusBarItem key={x.id} item={x} />)}
          <div class="StatusBarDivider !ml-auto"></div>
          {right.map(x => <StatusBarItem key={x.id} item={x} />)}
        </div>
      )
    }
  },
})
