import { computed } from "vue"
import { defineService } from "@maine-cat/common/service"
import { useI18n } from "@maine-cat/i18n"
import { LightBulbIcon } from "@heroicons/vue/24/outline"
import { useCommandService } from "../command"
import { useStatusBarService } from "../statusbar"
import { hotkeyToDisplay, normalizeHotkey } from "../keybinding"
import { useSpotlightService } from "./spotlight"

export const useCommandsSpotlight = defineService({
  id: "spotlight.commands",
  setup() {
    const { open } = useSpotlightService()
    const { commands, useCommand } = useCommandService()
    const { useStatusBarItem } = useStatusBarService()
    const { t, fallbackLang } = useI18n()
    const dt = t.as(fallbackLang)

    const openCommandsSpotlight = useCommand({
      id: "spotlight.open",
      condition: () => true,
      handler() {
        const displayEntries = [...commands.values()].filter(x => x.entry.display)
        const items = displayEntries.map(x => {
          const { id } = x.entry
          const title = t(`commands.${id}`, undefined)
          const label = dt(`commands.${id}`, undefined)
          return {
            key: id,
            title: title || id,
            label: label === title ? undefined : label,
            keybinding: x.keybinding?.value,
            command: x.command,
          }
        })

        open({
          type: "select",
          items,
          onAccept(item) {
            item.command()
          },
        })
      },
      defaultKeybinding: normalizeHotkey(["ctrl", "K"]),
    })

    useStatusBarItem({
      id: "spotlight.open",
      order: 100,
      position: "left",
      icon: LightBulbIcon,
      content: computed(() => t("commands.spotlight.name")),
      hoverContent: computed(() => hotkeyToDisplay(openCommandsSpotlight.keybinding)),
      onClick: openCommandsSpotlight,
    })

    return { openCommandsSpotlight }
  },
})
