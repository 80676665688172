import { Component, VNodeChild, h } from "vue"
import { WidgetState } from "./widget"
import { URI } from "vscode-uri/lib/umd"

export type WidgetComponent = Component<{
  widget?: WidgetState,
  query?: string
  fragment?: string
}>

const registrations = new Map<string, WidgetComponent>()

const widgetSchemeName = "widget"

export function renderWidgetByUri(widget: WidgetState, NotFoundWidget?: WidgetComponent): VNodeChild {
  try {
    const { uri } = widget
    const { scheme, path, query, fragment } = URI.parse(uri)

    if (scheme !== widgetSchemeName) {
      console.warn(`Received non-widget uri: ${uri}`)
      return null
    }

    if (registrations.has(path))
      return h(registrations.get(path)!, { widget, query, fragment })

    console.warn(`Widget "${path}" is not registered. Rendering uri "${uri}"`)

    if (!NotFoundWidget)
      return null

    return h(NotFoundWidget, { widget, query, fragment })
  }
  catch (error) {
    console.error(error)
  }
  return null
}

export function createWidgetUri(path: string, query?: string | Record<string, string>, fragment?: string) {
  if (typeof query !== "string")
    query = new URLSearchParams(query).toString()
  return URI.from({ scheme: widgetSchemeName, path, query, fragment }).toString()
}

export function getUriWidget(uri: string) {
  const { scheme, path } = URI.parse(uri)
  if (scheme !== widgetSchemeName) {
    console.warn(`Received non-widget uri: ${uri}`)
    return null
  }
  return path
}

// TODO: move to a service?
export function registerWidget(path: string, component: WidgetComponent) {
  registrations.set(path, component)
}
