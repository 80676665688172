import { defineComponent } from "vue"
import { LayoutPath, SplitLayoutProps, useLayoutService } from "@maine-cat/service/layout"
import { Splitter, useUncontrolled } from "@maine-cat/components"
import { WorkspaceLayout } from "./WorkspaceLayout"

export const SplitLayout = defineComponent({
  name: "SplitLayout",
  props: {
    layout: { type: Object as () => SplitLayoutProps, required: true },
    path: { type: Array as () => LayoutPath, required: true },
  },
  setup(props) {
    const { atomics: { adjustSplit } } = useLayoutService()
    const splitValue = useUncontrolled(
      props.layout.splitValue,
      () => props.layout.splitValue,
      v => adjustSplit({ path: props.path, value: v })
    )
    return () => {
      const { splitProps, before, after } = props.layout
      return (
        <Splitter
          class="SplitLayout grow"
          panelClass="flex"
          border
          {...splitProps}
          v-model={splitValue.value}
          v-slots={{
            before: () =>
              <WorkspaceLayout layout={before} path={[...props.path, "before"]} />,
            after: () =>
              <WorkspaceLayout layout={after} path={[...props.path, "after"]} />,
          }} />
      )
    }
  },
})
