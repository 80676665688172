import { reactive } from "vue"
import { defineService } from "@maine-cat/common/service"
import { LayoutPath, SplitProps, TabProps } from "../layout"

export interface DragAndDropState {
  dragging?: {
    tab: TabProps
    source?: {
      path: LayoutPath // empty for external dragging
      index: number
    }
  }
  dropping?: {
    path: LayoutPath
    index?: number
    droppingElement?: HTMLElement
    split?: SplitProps
  }
}

export const useDnDService = defineService({
  id: "dnd",
  setup() {
    const dndState = reactive<DragAndDropState>({})

    return { dndState }
  },
})
