import { FuzzyMatchString, fuzzyMatch } from "./score"

type StringProps<T extends object, K = keyof T> =
  K extends keyof T ? T[K] extends string | undefined ? K : never : never

export type FuzzySearchConfig<T extends object, K extends StringProps<T> = StringProps<T>> = {
  [key in K]?: number
}

export interface FuzzySearchResultItem<T extends object, K extends StringProps<T> = StringProps<T>> {
  /**
   * Fuzzy score
   */
  score: number
  /**
   * Original item
   */
  item: T
  /**
   * Matched props
   */
  matches: Record<K, FuzzyMatchString | undefined>
}

export function fuzzySearch<T extends object, K extends StringProps<T> = StringProps<T>>(
  config: FuzzySearchConfig<T, K>,
  query: string,
  items: T[]
): FuzzySearchResultItem<T>[] {
  const results: FuzzySearchResultItem<T>[] = []

  for (const item of items) {
    const matches = Object.keys(config).flatMap(prop => {
      const value = item[prop as K]
      const boost = config[prop as K]
      if (!value || !boost)
        return []
      const [match, score] = fuzzyMatch(query, value as string)
      return [{ prop, match, score: score * boost }]
    })

    const entries = matches.map(x => [x.prop, x.match])
    const score = matches.reduce((s, m) => s + m.score, 0)
    if (score <= 0)
      continue

    results.push({
      score, item, matches: Object.fromEntries(entries),
    })
  }

  return results.sort((a, b) => b.score - a.score)
}

const checkCancellationInterval = 20

export async function fuzzySearchAsync<T extends object, K extends StringProps<T> = StringProps<T>>(
  config: FuzzySearchConfig<T, K>,
  query: string,
  items: T[],
  cancellationToken?: CancellationToken
): Promise<FuzzySearchResultItem<T>[]> {
  const results: FuzzySearchResultItem<T>[] = []

  for (const [idx, item] of items.entries()) {
    if ((idx + 1) % checkCancellationInterval === 0)
      await cancellationToken?.()

    const matches = Object.keys(config).flatMap(prop => {
      const value = item[prop as K]
      const boost = config[prop as K]
      if (!value || !boost)
        return []
      const [match, score] = fuzzyMatch(query, value as string)
      return [{ prop, match, score: score * boost }]
    })

    const entries = matches.map(x => [x.prop, x.match])
    const score = matches.reduce((s, m) => s + m.score, 0)
    if (score <= 0)
      continue

    results.push({
      score, item, matches: Object.fromEntries(entries),
    })
  }

  await cancellationToken?.()
  return results.sort((a, b) => b.score - a.score)
}
