import { defineComponent } from "vue"
import { ScaleInOut } from "@maine-cat/components"
import { useToastService } from "@maine-cat/service/notification"

export const Toast = defineComponent({
  name: "Toast",
  setup() {
    const { currentToast, close } = useToastService()
    let lastContent: string

    return () =>
      <ScaleInOut show={!!currentToast.value}>
        <div role="tooltip" onClick={close}
          class="fixed bottom-32 left-1/2 -translate-x-1/2 px-4 py-2 cursor-pointer bg-zinc-600 text-white rounded-lg shadow">
          {lastContent = currentToast.value?.content || lastContent}
        </div>
      </ScaleInOut>
  },
})
