import { defineComponent } from "vue"
import { LayoutPath, WorkspaceLayoutProps, useLayoutService } from "@maine-cat/service/layout"
import { EmptyLayout } from "./EmptyLayout"
import { SplitLayout } from "./SplitLayout"
import { TabsLayout } from "./TabsLayout"

export const WorkspaceLayout = defineComponent({
  name: "WorkspaceLayout",
  props: {
    layout: { type: Object as () => WorkspaceLayoutProps, required: true },
    path: { type: Array as () => LayoutPath, required: true },
  },
  setup(props) {
    return () => {
      switch (props.layout.type) {
        case "empty":
          return <EmptyLayout layout={props.layout} path={props.path} />
        case "split":
          return <SplitLayout layout={props.layout} path={props.path} />
        case "tabs":
          return <TabsLayout layout={props.layout} path={props.path} />
      }
    }
  },
})

export const WorkspaceLayoutRoot = defineComponent({
  name: "WorkspaceLayoutRoot",
  setup() {
    const { layout } = useLayoutService()
    return () => <WorkspaceLayout layout={layout.value} path={[]} />
  },
})
