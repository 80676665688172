import { defineService } from "@maine-cat/common/service"
import { useStatusBarService } from "../statusbar"
import { useLayoutService } from "../layout"
import { getUriWidget } from "../widget"
import { HomeIcon } from "@heroicons/vue/24/outline"
import { useCommandService } from "../command"

export const useHomeService = defineService({
  id: "home",
  setup() {

    const { tabs, focusTab, openNewTab } = useLayoutService()
    const { useStatusBarItem } = useStatusBarService()
    const { useCommand } = useCommandService()

    const openHome = useCommand({
      id: "home.open",
      condition: () => true,
      handler() {
        const homeTab = tabs.value.find(x => getUriWidget(x.viewUri) === "home")
        if (homeTab) {
          focusTab({ tabId: homeTab.id })
        }
        else {
          openNewTab({
            title: "Home",
            uri: "widget:home",
          })
        }
      },
      display: true,
    })

    useStatusBarItem({
      id: "home",
      content: "Home",
      position: "left",
      order: 1000,
      icon: HomeIcon,
      onClick: openHome,
    })

    return { openHome }
  },
})
