import { ref } from "vue"
import { cuid } from "@maine-cat/common/cuid"
import { defineService } from "@maine-cat/common/service"

export const useToastService = defineService({
  id: "toast",
  setup() {

    const currentToast = ref<{ id: string, content: string }>()

    function toast(content: string, timeout = 4000) {
      const id = cuid()
      currentToast.value = { id, content }

      setTimeout(() => {
        if (currentToast.value?.id === id)
          currentToast.value = undefined
      }, timeout)
    }

    function close() {
      currentToast.value = undefined
    }

    return { currentToast, toast, close }
  },
})
