import { useConfigurationService } from "@maine-cat/service/configuration"
import { I18nFunction, createI18n } from "@maine-cat/service/i18n"
import { defineService } from "@maine-cat/common/service"
import { watchEffect } from "vue"

import { Translation, en } from "./en"
import { zh } from "./zh"

const translations = { en, zh }

export type { Translation, I18nFunction as TranslationFn }
export type Language = keyof typeof translations

const fallbackLang = "en" as Language

export const useI18n = defineService({
  id: "i18n",
  setup() {
    const { useConfig } = useConfigurationService()

    const defaultLang = navigator.languages.map(x => x.split("-")[0]).find(x => x in translations) as Language
    const lang = useConfig<Language>({ id: "i18n", default: defaultLang || fallbackLang })
    const t = createI18n<Translation, Language>(translations, lang, fallbackLang)

    watchEffect(() => {
      document.documentElement.lang = lang.value
    })

    return { t, lang, translations, fallbackLang }
  },
})
