import { VNodeChild, computed, ref, shallowReactive } from "vue"
import { cuid } from "@maine-cat/common/cuid"
import { defineService } from "@maine-cat/common/service"
import { BellAlertIcon } from "@heroicons/vue/24/outline"
import { useCommandService } from "../command"
import { useStatusBarService } from "../statusbar"
import { hotkeyToDisplay } from "../keybinding"
import { Color } from "@maine-cat/components"

export type NotificationEntry = {
  /**
   * Unique id
   */
  id?: string

  /**
   * Title
   */
  title?: MaybeRef<string | undefined>
  /**
   * Text content
   */
  content?: MaybeRef<string | undefined>
  /**
   * Icon
   */
  icon?: MaybeRef<any>

  /**
   * Color
   */
  color?: MaybeRef<Color | undefined>

  /**
   * Overrides previous content and icon definitions
   */
  renderContent?: () => VNodeChild

  /**
   * Progress indicator from 0 to 1
   */
  progress?: MaybeRef<number | undefined | boolean>

  /**
   * Confirm handler
   */
  onConfirm?: () => void
  confirmText?: MaybeRef<string | undefined>
  /**
   * Confirm handler
   */
  onDetail?: () => void
  detailText?: MaybeRef<string | undefined>
  /**
   * Dismiss handler
   */
  onDismiss?: () => void
  dismissText?: MaybeRef<string | undefined>

  /**
   * Overrides actions section
   */
  renderActions?(): VNodeChild
}

export const useNotificationService = defineService({
  id: "notification",
  setup() {

    const { useCommand } = useCommandService()
    const { useStatusBarItem } = useStatusBarService()

    const openState = ref(false)
    const notifications = shallowReactive<NotificationEntry[]>([])

    const openNotifications = useCommand({
      id: "notification.open",
      condition: () => true,
      handler: () => {
        openState.value = true
      },
      display: true,
    })

    useStatusBarItem({
      id: "notification",
      position: "right",
      order: 1000,
      icon: BellAlertIcon,
      content: computed(() => notifications.length ? String(notifications.length) : undefined),
      hoverContent: computed(() => hotkeyToDisplay(openNotifications.keybinding)),
      onClick: openNotifications,
    })

    function notify(entry: NotificationEntry) {
      if (!entry.id)
        entry.id = cuid()
      notifications.push(entry)
      openState.value = true

      function dismiss() {
        close(entry.id!, false)
      }
      return dismiss
    }

    function close(id: string | number, isConfirm: boolean) {
      const idx = typeof id === "number" ? id : notifications.findIndex(x => x.id === id)
      if (idx >= 0 && idx < notifications.length) {
        const entry = notifications[idx]
        if (isConfirm)
          entry.onConfirm?.()
        else
          entry.onDismiss?.()
        notifications.splice(idx, 1)
        if (notifications.length <= 0)
          openState.value = false
      }
    }

    const dismissAll = useCommand({
      id: "notification.dismissAll",
      condition: () => true,
      handler: () => {
        while (notifications.length)
          close(notifications.length - 1, false)
      },
      display: true,
    })

    return { notifications, notify, close, dismissAll, openState, openNotifications }
  },
})
