import { unref, watchEffect } from "vue"
import { defineService } from "@maine-cat/common/service"
import { KeybindingEntry } from "./types"
import { hotkeyFromEvent, normalizeHotkey } from "./utils"

export const useKeybindingService = defineService({
  id: "keybinding",
  setup() {
    const activeKeybinding = new Map<string, KeybindingEntry[]>()

    watchEffect(cleanup => {
      function handleKeyDown(e: KeyboardEvent) {
        // ignore repeated input
        if (e.repeat) return
        const key = hotkeyFromEvent(e)
        if (!key) return
        const list = activeKeybinding.get(key) || []
        for (const binding of list) {
          // find first available handler
          if (binding.condition()) {
            e.preventDefault()
            e.stopPropagation()
            binding.action()
            break
          }
        }
      }
      window.addEventListener("keydown", handleKeyDown)
      cleanup(() => window.removeEventListener("keydown", handleKeyDown))
    })

    function useKeybinding(keybinding: KeybindingEntry) {
      watchEffect(cleanup => {
        const strKey = unref(keybinding.key)
        if (!strKey)
          return

        const key = normalizeHotkey(strKey, false)
        const list = activeKeybinding.get(key) || []
        list.push(keybinding)
        activeKeybinding.set(key, list)

        cleanup(() => {
          const list = activeKeybinding.get(key) || []
          activeKeybinding.set(key, list.filter(x => x.id !== keybinding.id))
        })
      })
    }

    return { activeKeybinding, useKeybinding }
  },
})
