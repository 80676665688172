import { shallowReactive } from "vue"
import { defineService } from "@maine-cat/common/service"
import { AbstractFileSystem, DirectoryEntry, FileEntry, FileSystemState, requestLast, requestNew } from "@maine-cat/common/filesystem"
import { useKeyValueStore } from "../kv"
import { useNotificationService } from "../notification"

export interface CachedDirectoryEntry extends DirectoryEntry {
  children?: CachedEntry[]
}

export type CachedEntry = FileEntry | CachedDirectoryEntry

export interface CachedDirectoryState {
  [id: string]: CachedEntry[]
}

export const useWorkingDirectoryService = defineService({
  id: "working-directory",
  setup() {

    const { useStore } = useKeyValueStore()
    const { notify } = useNotificationService()
    const historyStore = useStore<FileSystemState>("working-directory-histories")

    const lastDirectories: Record<string, FileSystemState> = shallowReactive({})
    const directories: Record<string, AbstractFileSystem> = shallowReactive({})

    // load last directories
    historyStore.keys().then(ids => {
      for (const id of ids) {
        historyStore.get(id).then(async history => {
          if (!history)
            return
          lastDirectories[id] = {
            id: history.id,
            type: history.type,
            name: history.name,
          }
        })
      }
    })

    async function openLast(id: string) {
      const history = await historyStore.get(id)
      if (!history)
        throw new Error(`FS History not found for id ${id}`)

      try {
        const [state, fs] = await requestLast(history)
        historyStore.set(id, state)
        directories[fs.id] = fs
        delete lastDirectories[id]
      }
      catch (error) {
        console.error(error)
        notify({
          title: "Failed to load last opened directory",
          content: `Directory: ${history.name}(${id})`,
          color: "error",
        })
      }
    }

    async function openDirectory(type: string) {
      try {
        const [state, fs] = await requestNew(type)
        historyStore.set(fs.id, state)
        directories[fs.id] = fs
        return fs.id
      }
      catch (error) {
        notify({
          title: "Failed to open directory",
          color: "error",
        })
        console.error(error)
      }
    }

    return { directories, openDirectory, lastDirectories, openLast }
  },
})
