
export function fuzzyScore(query: string, target: string): [number, number[]] {
  const queryLength = query.length
  const targetLength = target.length
  if (queryLength > targetLength)
    return [0, []]

  const queryLower = query.toLocaleLowerCase()
  const targetLower = target.toLocaleLowerCase()

  const matches: number[][] = []
  const scores: number[][] = []

  for (let q = 0; q < queryLength; q++) {
    matches[q] = []
    scores[q] = []
    for (let t = 0; t < targetLength; t++) {
      const left = t > 0 ? scores[q][t - 1] : 0
      const diag = t > 0 && q > 0 ? scores[q - 1][t - 1] : 0
      const match = t > 0 && q > 0 ? matches[q - 1][t - 1] : 0

      const matchScore = calcScore(query[q], queryLower[q], target[t], targetLower[t], target, t, match)
      const score = diag + matchScore

      if (matchScore > 0 && score > left) {
        matches[q][t] = match + 1
        scores[q][t] = score
      }
      else {
        matches[q][t] = 0
        scores[q][t] = left
      }
    }
  }

  const positions: number[] = []
  let q = queryLength - 1, t = targetLength - 1
  while (q >= 0 && t >= 0) {
    if (matches[q][t] > 0)
      positions.push(t), q--, t--
    else
      t--
  }
  if (positions.length < query.length) {
    // consider as no match
    return [0, []]
  }
  return [scores[queryLength - 1][targetLength - 1], positions.reverse()]
}

function calcScore(
  a: string, aLower: string,
  b: string, bLower: string,
  target: string, t: number,
  matches: number
) {
  let score = 0
  if (aLower !== bLower)
    return score
  if (a === b)
    score += 2
  if (matches)
    score += matches * 5
  if (t === 0)
    score += 8
  else if (/[,.?!:;'"/|\\-_ ]/.test(target[t - 1]))
    score += 4 // is start of word/sentence
  else if (b !== bLower && matches === 0)
    score += 3

  return score
}

export interface FuzzyMatchSegment {
  text: string
  match?: boolean
}
export type FuzzyMatchString = Array<FuzzyMatchSegment>

// see https://github.dev/microsoft/vscode/blob/main/src/vs/base/common/fuzzyScorer.ts

export function fuzzyMatch(query: string, target: string): [FuzzyMatchString, number] {
  const [score, matches] = fuzzyScore(query, target)

  const result: FuzzyMatchString = []
  let start = 0, end = 0
  for (let i = 0; i < matches.length; i++) {
    const idx = matches[i] + 1
    if (idx === end + 1) {
      end = idx
    }
    else {
      if (start < end)
        result.push({ text: target.substring(start, end), match: true })
      result.push({ text: target.substring(end, idx - 1) })
      start = idx - 1, end = idx
    }
  }
  if (start < end)
    result.push({ text: target.substring(start, end), match: true })
  if (end < target.length)
    result.push({ text: target.substring(end) })

  return [result, score]
}
