import { computed, ref, unref, watchEffect } from "vue"
import { defineService } from "@maine-cat/common/service"
import { FuzzySearchConfig, FuzzySearchResultItem, fuzzySearchAsync } from "@maine-cat/common/fuzzy"
import { createCancellation } from "@maine-cat/common/cancellation"
import { cuid } from "@maine-cat/common/cuid"
import { delay } from "@maine-cat/common/utils"
import { useUncontrolled } from "@maine-cat/components"
import { SpotlightOption, SpotlightOptionItem, SpotlightState } from "./types"

const groupId = "spotlight"

const searchConfig: FuzzySearchConfig<SpotlightOptionItem> = {
  title: 10,
  label: 5,
  detail: 1,
}

export const useSpotlightService = defineService({
  id: groupId,
  setup() {
    const state = ref<SpotlightState>()

    const activeIndex = ref(0)

    const input = useUncontrolled<string>("", () => undefined, value => {
      if (state.value) {
        activeIndex.value = 0
        state.value.option.onInput?.(value)
      }
    })

    const optionsList = computed(() =>
      state.value?.option.type === "select"
        ? unref(state.value.option.items) : [])

    const searchResultList = ref<FuzzySearchResultItem<SpotlightOptionItem>[]>()

    watchEffect(cleanup => {
      const q = unref(input)
      const list = unref(optionsList)

      if (!q || !list.length) {
        searchResultList.value = undefined
      }
      else {
        const { cancel, token } = createCancellation()
        cleanup(cancel)
        fuzzySearchAsync(searchConfig, q, list, token)
          .then(res => searchResultList.value = res)
      }
    })

    let shouldPreventFlash = false
    watchEffect(() => {
      if (!state.value) {
        shouldPreventFlash = true
        setTimeout(() => shouldPreventFlash = false, 100)
      }
    }, { flush: "sync" })

    async function open<T extends SpotlightOptionItem>(option: SpotlightOption<T>) {
      if (state.value || shouldPreventFlash) {
        state.value = undefined
        await delay(50)
      }
      input.value = option.defaultInput || ""
      state.value = {
        id: cuid(),
        option,
      }
    }

    function action(index?: number) {
      const spotlight = state.value
      if (spotlight) {
        const list = searchResultList.value?.map(x => x.item) || optionsList.value
        state.value = undefined
        const inputValue = input.value
        if (spotlight.option.type === "select") {
          const item = list[index ?? activeIndex.value]
          if (item)
            spotlight.option.onAccept?.(item)
          else
            spotlight.option.onCancel?.()
        }
        else {
          spotlight.option.onAccept?.(inputValue)
        }
      }
    }

    function cancel() {
      if (state.value) {
        state.value.option.onCancel?.()
        state.value = undefined
      }
    }

    return {
      state, open, action, cancel,
      input, activeIndex, optionsList, searchResultList,
    }
  },
})
