import { Ref, watchEffect } from "vue"
import { useDnDService } from "./dnd"

export function useDnDIndicator(indicator: Ref<HTMLElement | undefined>) {
  const { dndState } = useDnDService()
  watchEffect(() => {
    const el = indicator.value
    if (!el) return

    const { dropping } = dndState
    if (dropping && dropping.droppingElement) {
      el.style.display = "block"
      el.style.opacity = ""
      el.ontransitionend = null

      const rect = dropping.droppingElement.getBoundingClientRect()

      el.style.left = rect.left + "px"
      el.style.top = rect.top + "px"
      el.style.width = rect.width + "px"
      el.style.height = rect.height + "px"

      if (dropping.split) {
        const { direction, bias } = dropping.split
        if (direction === "horizontal") {
          el.style.width = rect.width / 2 + "px"
          if (bias === "after")
            el.style.left = rect.left + rect.width / 2 + "px"
        }
        else {
          el.style.height = rect.height / 2 + "px"
          if (bias === "after")
            el.style.top = rect.top + rect.height / 2 + "px"
        }
      }
    }
    else {
      el.style.opacity = "0"
      el.ontransitionend = () => {
        el.style.display = "none"
      }
    }
  })
}
