import { defineComponent } from "vue"
import { Button, Dialog } from "@maine-cat/components"
import { useNotificationService } from "@maine-cat/service/notification"
import { useI18n } from "@maine-cat/i18n"
import { ChevronDownIcon, TrashIcon } from "@heroicons/vue/24/outline"
import { NotificationItem } from "./NotificationItem"

export const Notification = defineComponent({
  name: "Notification",
  setup() {

    const { t } = useI18n()
    const { notifications, openState, dismissAll } = useNotificationService()
    const close = () => openState.value = false

    return () =>
      <Dialog
        open={openState.value}
        onClose={close}
        class="origin-bottom"
        containerClass="absolute right-4 bottom-14 min-w-[320px] items-end"
        noBackdrop>
        <div class="divide-y">
          {notifications.map(x => <NotificationItem key={x.id} item={x} />)}
        </div>
        {notifications.length > 0 && <div class="border-b-2" />}
        <div class="bg-zinc-100 dark:bg-var-600 px-4 py-2 flex items-center space-x-1">
          <div class="mr-auto text-zinc-400 text-sm">
            {!notifications.length && t("notification.no")}
          </div>
          <Button
            disabled={!notifications.length}
            onClick={dismissAll} size="icon" class="p-1 rounded-lg" color="secondary">
            <TrashIcon class="h-4 w-4" />
          </Button>
          <Button onClick={close} size="icon" class="p-1 rounded-lg">
            <ChevronDownIcon class="h-4 w-4" />
          </Button>
        </div>
      </Dialog>
  },
})
