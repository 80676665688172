import { caniuse } from "../caniuse"
import { cuid } from "../cuid"
import { AbstractFileSystem, FSEntry, FSPath, FileSystemFactory, allowedWriteExt } from "./types"

const type = "fs-access"

interface FSAccessHistory {
  id: string
  type: typeof type
  name: string
  root: FileSystemDirectoryHandle
}

export const fsAccessFactory: FileSystemFactory = {
  type,
  async requestNew() {
    if (!caniuse("FileSystemAccess"))
      throw new TypeError("FileSystemAccess is not available")

    const id = cuid()
    const root = await window.showDirectoryPicker({ mode: "readwrite" })

    const history: FSAccessHistory = { id, type, name: root.name, root }
    return [history, makeFS(id, root)]
  },

  async requestLast(history: FSAccessHistory) {

    if (!("type" in history && history.type === type))
      throw new Error("Unexpected history to restore file system access")

    const permission = await history.root.queryPermission({ mode: "readwrite" })

    if (permission === "denied")
      throw new Error("File system access denied")

    if (permission === "prompt") {
      const state = await history.root.requestPermission({ mode: "readwrite" })
      if (state !== "granted")
        throw new Error("File system access denied")
    }

    return [history, makeFS(history.id, history.root)]
  },
}

function makeFS(id: string, root: FileSystemDirectoryHandle): AbstractFileSystem {

  async function resolveDir(path: FSPath, current = root): Promise<FileSystemDirectoryHandle> {
    if (!path.length)
      return current

    const [next, ...rest] = path
    current = await current.getDirectoryHandle(next)

    return await resolveDir(rest, current)
  }

  const fs: AbstractFileSystem = {
    id,
    type,
    name: root.name,
    available: true,

    async listDir(dirname) {
      const dir = await resolveDir(dirname)
      const entries: FSEntry[] = []
      for await (const entry of dir.values()) {
        if (entry.kind === "file") {
          const file = await entry.getFile()
          entries.push({
            name: entry.name,
            type: "file",
            size: file.size,
            lastModified: new Date(file.lastModified).toISOString(),
          })
        }
        else if (entry.kind === "directory") {
          entries.push({
            name: entry.name,
            type: "directory",
          })
        }
        else {
          throw new Error("Unexpected entry")
        }
      }
      return entries
    },

    async readFile(filename) {
      if (filename.length <= 0)
        throw new Error("Unexpected file name")

      const name = filename.pop()!
      const dir = await resolveDir(filename)
      const file = await dir.getFileHandle(name)

      return await file.getFile()
    },

    async writeFile(filename, data) {
      if (filename.length <= 0)
        throw new Error("Unexpected file name")

      if (!filename[filename.length - 1].endsWith(allowedWriteExt))
        throw new Error(`File name to write must end with ${allowedWriteExt}`)

      const name = filename.pop()!
      const dir = await resolveDir(filename)
      const file = await dir.getFileHandle(name)

      const stream = await file.createWritable({ keepExistingData: false })

      await stream.write(data)
      await stream.close()
    },
  }
  return fs
}
