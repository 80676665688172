import { defineComponent } from "vue"
import { WidgetState } from "@maine-cat/service/widget"
import { useWorkingDirectoryService } from "@maine-cat/service/workingDirectory"
import { FileBrowser, PendingFileBrowser } from "./Browser"
import { Button } from "@maine-cat/components"

const WorkingDirectories = defineComponent({
  name: "WorkingDirectories",
  setup() {
    const { directories, openDirectory, lastDirectories, openLast } = useWorkingDirectoryService()
    return () => {
      const list = Object.keys(directories).concat(Object.keys(lastDirectories))
        .sort((a, b) => a.localeCompare(b))
      return (
        <div class="p-4 space-y-4">
          <div class="max-w-4xl mx-auto">
            {list.map(id => {
              if (id in lastDirectories) {
                const dir = lastDirectories[id]
                return <PendingFileBrowser key={id} directory={dir.name} type={dir.type} connect={() => openLast(id)} />
              }
              return <FileBrowser key={id} directory={id} />
            })}
          </div>
          <div class="mx-auto space-x-2 flex">
            <Button onClick={() => openDirectory("fs-access")}>
              Open local directory
            </Button>
            <Button onClick={() => openDirectory("onedrive")}>
              Open Onedrive
            </Button>
          </div>
        </div>
      )
    }
  },
})

export const Home = defineComponent({
  name: "Home",
  props: {
    widget: { type: Object as () => WidgetState, required: true },
    query: String,
    fragment: String,
  },
  setup(props) {
    props.widget.title = "Home"

    return () =>
      <div class="p-4 space-y-4">
        <div class="flex items-center justify-center pt-4">
          <img src="/favicon.svg" class="h-24 w-24 mr-8" />
          <div>
            <h1 class="text-xl mb-2">Maine CAT App</h1>
            <h2 class="text-base text-zinc-500">Computer Aided Translation for Web</h2>
            <div class="text-sm text-var-400 color-primary cursor-pointer hover:underline">
              Quick start guide
            </div>
          </div>
        </div>
        <div class="max-w-4xl mx-auto">
          <WorkingDirectories />
        </div>
      </div>
  },
})
