import { Tooltip } from "@maine-cat/components"
import { defineComponent, h, unref } from "vue"
import { StatusBarItem as Item } from "@maine-cat/service/statusbar"

const rootClass = `
  px-2 space-x-1 text-white flex items-center transition hover:bg-white/10 hover:cursor-pointer
  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4
  focus-visible:outline-var-500/40 dark:focus-visible:outline-var-200/40
  [-webkit-tap-highlight-color:transparent]`

export const StatusBarItem = defineComponent({
  name: "StatusBarItem",
  props: {
    item: { type: Object as () => Item, required: true },
  },
  setup(props) {
    return () => {
      const { item } = props
      const textContent = unref(item.content)
      const hoverContent = unref(item.hoverContent)
      const icon = unref(item.icon)

      const content =
        <button class={rootClass} onClick={item.onClick}>
          {icon && h(icon, { class: "w-4 h-4" })}
          {(item.renderContent || textContent) &&
            <span class="text-xs">
              {item.renderContent
                ? item.renderContent()
                : textContent}
            </span>}
        </button>

      if (item.renderHoverContent || hoverContent) {
        return (
          <Tooltip
            class="h-full flex items-stretch"
            tooltipClass="whitespace-pre cursor-auto"
            placement="top"
            v-slots={{
              tip: () => <>
                {item.renderHoverContent
                  ? item.renderHoverContent()
                  : hoverContent}
              </>,
            }}>
            {content}
          </Tooltip>
        )
      }

      return content
    }
  },
})
