import { computed, defineComponent, reactive, ref } from "vue"
import { useWorkingDirectoryService } from "@maine-cat/service/workingDirectory"
import { toHumanSize, useAsyncValue } from "@maine-cat/common/utils"
import { Button, Collapse, LinearProgress, List, ListItem } from "@maine-cat/components"
import dayjs from "dayjs"
import { FolderIcon } from "@heroicons/vue/24/solid"
import { useLayoutService } from "@maine-cat/service/layout"
import { FSEntry } from "@maine-cat/common/filesystem"
import { createWidgetUri } from "@maine-cat/service/widget"

export const PendingFileBrowser = defineComponent({
  name: "PendingFileBrowser",
  props: {
    directory: { type: String, required: true },
    type: { type: String, required: true },
    connect: { type: Function, required: true },
  },
  setup(props) {
    const loading = ref(false)
    function connect() {
      loading.value = true
      Promise.resolve(props.connect()).finally(() => loading.value = false)
    }
    return () =>
      <div class="max-w-4xl">
        <div class="p-2 text-lg flex items-center">
          <span>
            <span class="cursor-not-allowed font-bold">
              {props.directory}
            </span>
            <span class="mx-2">/</span>
          </span>
          <Button
            class="ml-auto rounded-full text-base"
            size="small" color="warn" loading={loading.value}
            onClick={connect}>
            {props.type}
          </Button>
        </div>
      </div>
  },
})

export const FileBrowser = defineComponent({
  name: "FileBrowser",
  props: {
    directory: { type: String, required: true },
  },
  setup(props) {
    const { openNewTab } = useLayoutService()
    const { directories } = useWorkingDirectoryService()

    const path = reactive<string[]>([])
    const dir = computed(() => directories[props.directory])

    const current = useAsyncValue(computed(() => dir.value.listDir(path)))

    const open = ref(true)

    const handleClick = (entry: FSEntry) => {
      if (entry.type === "directory") {
        path.push(entry.name)
      }
      else {
        if (entry.size && entry.size > 10 * 1024 ** 2)
          return
        openNewTab({
          title: entry.name,
          uri: createWidgetUri("file-viewer", {
            wd: props.directory,
            file: path.concat(entry.name).join("/"),
          }),
        })
      }
    }

    return () => {
      const breads = [dir.value.name].concat(path)

      const list = current.value?.sort((a, b) =>
        a.type === b.type
          ? a.name.localeCompare(b.name)
          : a.type === "directory" ? -1 : 1)

      return (
        <div class="max-w-4xl">
          <div class="p-2 text-lg flex items-center">
            {breads.map((segment, i) =>
              <span key={i}>
                <span
                  class={["hover:underline cursor-pointer", i === 0 && "font-bold"]}
                  onClick={() => path.splice(i, path.length)}>
                  {segment}
                </span>
                <span class="mx-2">/</span>
              </span>)}
            <Button class="ml-auto rounded-full text-base" size="small" onClick={() => open.value = !open.value}>
              {dir.value.type}
            </Button>
          </div>

          <Collapse show={open.value}>
            <div class="relative h-96 overflow-y-auto shadow-inner">
              {current.loading &&
                <div class="absolute left-0 right-0 z-10 color-primary">
                  <LinearProgress />
                </div>}
              <List dense>
                {list?.map((entry, i) =>
                  <ListItem key={i} button onClick={() => handleClick(entry)}>
                    {entry.type === "directory"
                      ? <FolderIcon class="h-4 w-4 mr-2" />
                      : <span class="mr-6" />}
                    {entry.name}
                    {entry.type === "file" &&
                      <span class="ml-auto text-right font-mono text-sm text-zinc-500 dark:text-zinc-400">
                        <span>
                          {entry.lastModified && dayjs(entry.lastModified).format("YYYY/MM/DD HH:mm")}
                        </span>
                        <span class="inline-block w-24">
                          {entry.size && toHumanSize(entry.size)}
                        </span>
                      </span>}
                  </ListItem>)}
                {list?.length === 0 &&
                  <ListItem class="text-gray-400">
                    Empty Folder
                  </ListItem>}
              </List>
              {current.error && <div>{current.error}</div>}
            </div>
          </Collapse>
        </div>
      )
    }
  },
})
