import { common } from "./common"
import { diagnostic } from "./diagnostic"
import { configs } from "./configs"
import { commands } from "./commands"
import { notification } from "./notification"

export type Translation = typeof en

export const en = {
  ...common,
  diagnostic,
  configs,
  commands,
  notification,
}
