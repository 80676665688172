import { defineComponent } from "vue"
import { Button } from "@maine-cat/components"
import { allRequiredSatisfied } from "@maine-cat/common/caniuse"
import { CaniuseTable } from "./CaniuseTable"
import { BuildInfoTable } from "./BuildInfo"

const ExitAnywayButton = defineComponent({
  name: "ExitAnywayButton",
  setup() {
    return () => !allRequiredSatisfied.value &&
      <Button
        color="error" class="w-full"
        onClick={() => allRequiredSatisfied.value = true}>
        Exit unsupported mode anyway
      </Button>
  },
})

export const Diagnostics = defineComponent({
  name: "Diagnostics",
  props: {
    showExitButton: Boolean,
  },
  setup(props) {

    return () =>
      <div class="space-y-6">
        <CaniuseTable />
        <BuildInfoTable />
        {props.showExitButton && <ExitAnywayButton />}
      </div>
  },
})
