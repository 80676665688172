import { Ref, unref } from "vue"
import { defineService } from "@maine-cat/common/service"
import { useConfigurationService } from "../configuration"
import { useKeybindingService } from "../keybinding"
import { CommandEntry, CommandProxy } from "./types"

export const useCommandService = defineService({
  id: "command",
  setup() {
    const { useConfig } = useConfigurationService()
    const { useKeybinding } = useKeybindingService()

    const commands = new Map<string, {
      entry: CommandEntry<any>,
      keybinding?: Ref<string | undefined>,
      command: CommandProxy<any>,
    }>()

    function useCommand<T extends {}>(entry: CommandEntry<T>): CommandProxy<T> {
      const { id, condition, handler, resolveParameters } = entry
      if (commands.has(id) && !import.meta.hot) {
        if (import.meta.hot)
          return commands.get(id)!.command as any
        throw new Error(`Duplicated definition of command "${id}"`)
      }

      // add keybinding only if condition is defined
      if (condition) {
        const keybinding = useConfig({
          id: `keybinding.${id}`,
          default: entry.defaultKeybinding,
        })
        useKeybinding({
          id, condition, action: commandProxy, key: keybinding,
        })
        commands.set(id, { entry, keybinding, command: commandProxy as any })

        Object.defineProperty(commandProxy, "keybinding", {
          get() {
            return unref(keybinding)
          },
          set(value) {
            keybinding.value = value
          },
        })
      }
      else {
        commands.set(id, { entry, command: commandProxy as any })

        Object.defineProperty(commandProxy, "keybinding", {
          value: undefined,
          writable: false,
        })
      }

      async function commandProxy(partialParams?: Partial<T>) {
        if (condition && !condition())
          return

        if (resolveParameters) {
          const resolved = await resolveParameters(partialParams || {})
          handler(resolved)
          return
        }
        handler(partialParams as T)
      }
      return Object.assign(commandProxy, { handler, entry })
    }

    function getCommandKeybinding(id: string) {
      const keybinding = commands.get(id)?.keybinding
      return unref(keybinding)
    }

    return { commands, useCommand, getCommandKeybinding }
  },
})
