import { defineComponent } from "vue"
import { useI18n } from "@maine-cat/i18n"
import { ExternalLink, Typography } from "@maine-cat/components"
import { WrenchScrewdriverIcon } from "@heroicons/vue/24/solid"
import buildInfo from "build-info"

function row(tag: string, content: any, fallback = "Null") {
  return (
    <div class="flex items-center">
      <div class="basis-28">{tag}:</div>
      <div>{content ?? <Typography silent italic class="inline">{fallback}</Typography>}</div>
    </div>
  )
}

const remoteUrl = "https://github.com/K024/maine-cat-app"

export const BuildInfoTable = defineComponent({
  name: "BuildInfoTable",
  setup() {
    const { t } = useI18n()
    const { ci, date, env, gitInfo } = buildInfo
    const { branch, tag, abbreviatedSha, authorDate } = gitInfo
    return () =>
      <div>
        <Typography is="h3" class="flex items-center mb-6">
          <WrenchScrewdriverIcon class="w-6 h-6 mr-4" />
          {t("diagnostic.build_information")}
        </Typography>
        <div class="space-y-2 pl-4">
          {row("CI", ci, "No CI")}
          {row("Build at", date)}
          {row("Env", env)}
          {row("Git branch", branch &&
            <ExternalLink href={`${remoteUrl}/tree/${branch}`}>
              {branch}
            </ExternalLink>)}
          {row("Git tag", tag)}
          {row("Git commit", abbreviatedSha &&
            <ExternalLink href={`${remoteUrl}/commit/${abbreviatedSha}`}>
              {abbreviatedSha}
            </ExternalLink>)}
          {row("Git date", authorDate)}
        </div>
      </div>
  },
})
