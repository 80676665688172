
export const commands = {
  placeholder: "Commands...",

  spotlight: {
    name: "Spotlight",
    open: "Spotlight: Open",
  },

  layout: {
    openTab: "Layout: Open tab",
    moveTab: "Layout: Move tab",
    splitTab: "Layout: Split tab",
    closeTab: "Layout: Close tab",
    focusTab: "Layout: Focus tab",

    selectTab: "Select tab",
    splitLeft: "Split to left",
    splitRight: "Split to right",
    splitTop: "Split to top",
    splitBottom: "Split to bottom",
  },

  notification: {
    open: "Notification: Open",
    dismissAll: "Notification: Dismiss all",
  },

  home: {
    open: "Open Home page",
  },
}
