import { defineComponent } from "vue"
import { WidgetRenderer } from "./WidgetRenderer"
import { useLayoutService } from "@maine-cat/service/layout"

export const WidgetsContainer = defineComponent({
  name: "WidgetsContainer",
  setup() {
    const { tabs } = useLayoutService()
    return () => <>
      {tabs.value.map(x => <WidgetRenderer key={x.id} tabId={x.id} uri={x.viewUri} />)}
    </>
  },
})
