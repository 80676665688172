import { watch } from "vue"
import { useI18n } from "@maine-cat/i18n"
import { useNotificationService, useToastService } from "@maine-cat/service/notification"
import { DocumentArrowUpIcon } from "@heroicons/vue/24/outline"
import { hasUpdate, isFirstInstalled } from "./register"

export function useServiceWorkerNotification() {
  const { notify } = useNotificationService()
  const { toast } = useToastService()
  const { t } = useI18n()

  watch(hasUpdate, update => {
    if (update) {
      notify({
        title: t("notification.update_installed"),
        content: t("notification.confirm_to_activate"),
        icon: DocumentArrowUpIcon,
        onConfirm: update,
      })
    }
  }, { immediate: true })

  watch(isFirstInstalled, isFirstInstalled => {
    if (isFirstInstalled)
      toast(t("notification.offline_available"))
  }, { immediate: true })
}
