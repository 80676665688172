import { defineComponent, ref, toRef, unref, watchEffect } from "vue"
import { useWidgetService } from "@maine-cat/service/widget"

const focusClass = `
  focus-visible:outline focus-visible:outline-2 focus-visible:-outline-offset-2
  focus-visible:outline-var-500/40 dark:focus-visible:outline-var-200/40
  [-webkit-tap-highlight-color:transparent]`

export const TabWidgetView = defineComponent({
  name: "TabWidgetView",
  props: {
    tabId: { type: String, required: true },
    show: { type: Boolean },
  },
  setup(props) {
    const el = ref<HTMLDivElement>()
    const { useTabContentWidget } = useWidgetService()
    const widget = useTabContentWidget(toRef(props, "tabId"))

    watchEffect(cleanup => {
      const currentEl = el.value
      const targetEl = unref(widget.value?.rootElement)

      if (!currentEl || !targetEl)
        return

      currentEl.appendChild(targetEl)
      cleanup(() => currentEl.removeChild(targetEl))
    })

    // Handle focus in TabsLayout
    // const onFocusIn = () => {
    //   lastFocusTab.value = props.tabId
    // }

    return () =>
      <div
        ref={el}
        tabindex="0"
        class={[
          "TabWidgetView absolute inset-0 overflow-hidden",
          focusClass,
          props.show && widget.value ? "" : "hidden",
        ]} />
  },
})
