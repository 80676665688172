import { defineService } from "@maine-cat/common/service"
import { createKeyValueStore } from "@maine-cat/common/indexedDB"

export interface KeyValueStore<TDefault> {
  get<T = TDefault>(key: string): Promise<T | null>
  set<T = TDefault>(key: string, value: T): Promise<void>
  delete(key: string): Promise<void>
  keys(): Promise<string[]>
  persisted(): Promise<boolean>
}

const storeName = "kv-store"

export const useKeyValueStore = defineService({
  id: storeName,
  setup() {
    try {
      const objStore = createKeyValueStore(storeName)
      function useStore<T>(prefix: string) {
        const store: KeyValueStore<T> = {
          async get(key) { return await objStore.get([prefix, key]) },
          async set(key, value) { await objStore.set([prefix, key], value) },
          async delete(key) { await objStore.delete([prefix, key]) },
          async keys() {
            const range = IDBKeyRange.bound([prefix, ""], [prefix, "\uffff"])
            const keys = await objStore.keys(range) as [string, string][]
            return keys.map(x => x[1])
          },
          async persisted() { return await navigator.storage.persisted() },
        }
        return store
      }
      return { useStore }
    }
    catch (error) {
      const store: KeyValueStore<unknown> = {
        async get() { return null },
        async set() { /** noop */ },
        async delete() { /** noop */ },
        async keys() { return [] },
        async persisted() { return false },
      }
      function useStore(prefix: string) {
        return store
      }
      return { useStore }
    }
  },
})
