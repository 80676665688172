import { defineComponent } from "vue"
import { EmptyLayoutProps, LayoutPath, useLayoutService } from "@maine-cat/service/layout"
import { useDropLayout } from "@maine-cat/service/dnd"
import { useI18n } from "@maine-cat/i18n"
import { XMarkIcon } from "@heroicons/vue/24/solid"
import { useHomeService } from "@maine-cat/service/home"

export const EmptyLayout = defineComponent({
  name: "EmptyLayout",
  props: {
    layout: { type: Object as () => EmptyLayoutProps, required: true },
    path: { type: Array as () => LayoutPath, required: true },
  },
  setup(props) {
    const { t } = useI18n()
    const { openHome } = useHomeService()
    const { atomics: { closeTab } } = useLayoutService()
    const close = () => closeTab({ path: props.path, index: -1 })
    const layoutProps = useDropLayout(props)
    return () =>
      <div
        class="EmptyLayout grow relative flex items-center justify-center transition bg-zinc-100 dark:bg-zinc-800 text-zinc-500 select-none"
        {...layoutProps}
        onDblclick={openHome}
      >
        {t("empty")}
        {props.path.length > 0 &&
          <XMarkIcon class="absolute h-4 w-4 right-4 top-4 cursor-pointer select-none" onClick={close} />}
      </div>
  },
})
