import { defineComponent, watchEffect } from "vue"
import { allRequiredSatisfied } from "@maine-cat/common/caniuse"
import { useTheme } from "@maine-cat/components"
import { useHomeService } from "@maine-cat/service/home"
import { registerWidget } from "@maine-cat/service/widget"
import { NotSupportedPage } from "./diagnostic"
import { useServiceWorkerNotification } from "./serviceWorker"
import { DnDIndicator, WorkspaceLayoutRoot } from "./layout"
import { WidgetsContainer } from "./widget"
import { StatusBar } from "./status"
import { Spotlight } from "./spotlight"
import { Notification, Toast } from "./notification"
import { Home } from "./home"
import { FileViewer } from "./FileViewer"

// widget registrations
registerWidget("home", Home)
registerWidget("file-viewer", FileViewer)

// default workspace
export const Workspace = defineComponent({
  name: "Workspace",
  setup() {
    watchEffect(() => {
      document.body.classList.add("overflow-hidden")
    })
    useServiceWorkerNotification()
    useHomeService()

    return () =>
      <div class="h-screen bg-zinc-50 dark:bg-zinc-800 relative flex flex-col">
        <DnDIndicator />
        <WorkspaceLayoutRoot />
        <WidgetsContainer />
        <StatusBar />
        <Spotlight />
        <Notification />
        <Toast />
      </div>
  },
})

// app root
export const App = defineComponent({
  name: "App",
  setup() {
    useTheme()
    return () =>
      allRequiredSatisfied.value
        ? <Workspace />
        : <NotSupportedPage />
  },
})
