import { computed, defineComponent, watchEffect } from "vue"
import { WidgetState } from "@maine-cat/service/widget"
import { useWorkingDirectoryService } from "@maine-cat/service/workingDirectory"
import { useAsyncValue } from "@maine-cat/common/utils"
import { CircularProgress } from "@maine-cat/components"

const TextViewer = defineComponent({
  name: "TextViewer",
  props: {
    content: { type: Blob, required: true },
  },
  setup(props) {
    const text = useAsyncValue(computed(() => props.content.text()))
    const lines = computed(() => (text.value?.match(/\n/g)?.length || 0) + 1)
    return () =>
      <div class="flex font-mono p-2">
        <div class="text-right pr-4 w-12 flex-shrink-0 select-none text-zinc-400">
          {Array.from({ length: lines.value }, (_, i) => i + 1).map(i =>
            <pre>{i}</pre>)}
        </div>
        <pre class="border-l border-gray-400 pl-2">
          {text.value}
        </pre>
      </div>
  },
})

function dispatchViewer(name: string, file: Blob) {
  if (file.size > 10 * 1024 ** 2)
    return <div class="p-4">File too large to preview.</div>

  // const ext = name.split(".").pop()!.toLowerCase()

  // if (["txt", "md", "json", "html"].includes(ext))
  return <TextViewer content={file} />

  // return <div class="p-4">Unsupported file type</div>
}

export const FileViewer = defineComponent({
  name: "FileViewer",
  props: {
    widget: { type: Object as () => WidgetState, required: true },
    query: String,
    fragment: String,
  },
  setup(props) {

    const { directories } = useWorkingDirectoryService()

    const params = computed(() => {
      const query = new URLSearchParams(props.query || "")
      const wd = query.get("wd")
      const file = query.get("file")

      return { wd, file }
    })

    const fileContent = useAsyncValue(computed(() => {
      const { wd, file } = params.value
      const dir = directories[wd || ""]

      if (!dir || !file)
        throw new Error(`Invalid query: ${props.query}`)

      return dir.readFile(file.split("/"))
    }))

    watchEffect(() => {
      if (params.value.file)
        props.widget.title = params.value.file.split("/").pop()!
    })

    return () => <>
      {fileContent.loading &&
        <div class="flex items-center justify-center p-8 space-x-4">
          <CircularProgress />
          <div class="font-mono">
            Loading {params.value.file} ...
          </div>
        </div>}

      {fileContent.error &&
        <div class="flex items-center justify-center p-8 space-x-4">
          <div class="font-mono">
            Error opening file {params.value.file}.
          </div>
        </div>}

      {fileContent.value &&
        dispatchViewer(params.value.file || "", fileContent.value)}
    </>
  },
})
