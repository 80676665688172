import { defineComponent, ref } from "vue"
import { useDnDIndicator } from "@maine-cat/service/dnd"

export const DnDIndicator = defineComponent({
  name: "DnDIndicator",
  setup() {
    const el = ref<HTMLDivElement>()
    useDnDIndicator(el)
    return () =>
      <div ref={el}
        class="DnDIndicator fixed bg-var-500/10 dark:bg-var-200/10 color-primary transform-gpu transition-all duration-100 z-50 pointer-events-none" />
  },
})
