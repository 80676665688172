import { FileSystemFactory } from "./types"
import { fsAccessFactory } from "./fs-access"
import { onedriveFactory } from "./onedrive"

export * from "./types"

export const fsFactories: FileSystemFactory[] = [
  fsAccessFactory,
  onedriveFactory,
]

export async function requestNew(type: string) {
  const factory = fsFactories.find(x => x.type === type)
  if (!factory)
    throw new Error(`No file system factory named "${type}"`)

  return await factory.requestNew()
}

export async function requestLast(state: { type: string }) {

  const factory = fsFactories.find(x => x.type === state.type)
  if (!factory)
    throw new Error(`No file system factory named "${state.type}"`)

  return await factory.requestLast(state)
}
