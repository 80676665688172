import { defineService } from "@maine-cat/common/service"
import { VNodeChild, shallowReactive, unref, watchEffect } from "vue"

export type StatusBarItem = {
  /**
   * Unique id
   */
  id: string

  /**
   * @default "right"
   */
  position?: "left" | "right"

  /**
   * Higher is prior in each position
   * @default 0
   */
  order?: number

  /**
   * Text content
   */
  content?: MaybeRef<string | undefined>
  /**
   * Hover text
   */
  hoverContent?: MaybeRef<string | undefined>
  /**
   * Icon
   */
  icon?: MaybeRef<any>

  /**
   * Render function precedes previous content and icon definitions
   */
  renderContent?: () => VNodeChild
  renderHoverContent?: () => VNodeChild

  /**
   * Click handler
   */
  onClick?: () => void
}

export const useStatusBarService = defineService({
  id: "statusbar",
  setup() {
    const items = shallowReactive<StatusBarItem[]>([])

    function useStatusBarItem(item: MaybeRef<StatusBarItem | null | undefined>) {
      watchEffect(cleanup => {
        const value = unref(item)
        if (!value)
          return

        items.push(value)
        cleanup(() => {
          const idx = items.indexOf(value)
          if (idx >= 0) items.splice(idx, 1)
        })
      })
    }

    return { items, useStatusBarItem }
  },
})
