import { ExternalLink as L } from "@maine-cat/components"

export const diagnostic = {
  unsupported_browser: "Unsupported browser",
  unsupported_message: () => <>
    Current browser is not supported by Maine CAT app due to the lack of required functionalities.
    Please install a modern browser like
    {" "}<L href="https://www.google.com/chrome/">Chrome</L>,
    {" "}<L href="https://www.microsoft.com/en-us/edge">Edge</L> or
    {" "}<L href="https://www.mozilla.org/en-US/firefox/new/">Firefox</L>.
  </>,

  more_information: "More information",
  features_check: "Features check",

  build_information: "Build information",
  environment: "Environment",
  production: "Production",
  development: "Development",
}
