import { defineComponent } from "vue"
import { FuzzyMatchString, FuzzySearchResultItem } from "@maine-cat/common/fuzzy"
import { SpotlightOptionItem, useSpotlightService } from "@maine-cat/service/spotlight"
import { HotkeyHint } from "@maine-cat/components"

const MatchString = defineComponent({
  name: "MatchString",
  props: {
    class: null,
    string: [String, Array as () => FuzzyMatchString],
  },
  setup(props) {
    return () => {
      if (!props.string || !props.string.length)
        return null
      if (typeof props.string === "string")
        return props.string
      return <>
        {props.string.map(x => x.match
          ? <span class={props.class}>{x.text}</span>
          : x.text)}
      </>
    }
  },
})

export const SpotlightItem = defineComponent({
  name: "SpotlightItem",
  props: {
    item: {
      type: Object as () => SpotlightOptionItem | FuzzySearchResultItem<SpotlightOptionItem>,
      required: true,
    },
    index: { type: Number, required: true },
  },
  setup(props) {
    const { action, activeIndex } = useSpotlightService()
    const onClick = () => action(props.index)
    return () => {
      const item = "score" in props.item ? props.item.item : props.item
      const display = "score" in props.item ? props.item.matches : props.item
      const active = props.index === activeIndex.value
      return (
        <div onClick={onClick} class={[
          "relative p-2 transition cursor-pointer hover:bg-zinc-400/30 overflow-hidden",
          active && "bg-zinc-400/20",
        ]}>
          <div class="flex relative items-center">
            <div class="text-ellipsis">
              <MatchString class="text-var-500 dark:text-var-300 color-primary" string={display.title} />
              {display.label &&
                <span class="text-sm opacity-70 ml-2">
                  <MatchString class="text-var-500 color-primary" string={display.label} />
                </span>}
            </div>
          </div>
          {display.detail &&
            <div class="text-ellipsis text-zinc-500">
              <MatchString class="text-var-500 dark:text-var-300 color-primary" string={display.detail} />
            </div>}
          <div class="absolute flex right-2 top-1">
            {item.keybinding && <HotkeyHint keys={item.keybinding} />}
          </div>
        </div>
      )
    }
  },
})
