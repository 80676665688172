import { defineComponent, watchEffect } from "vue"
import { WidgetState } from "@maine-cat/service/widget"

export const NotFoundWidget = defineComponent({
  name: "NotFoundWidget",
  props: {
    widget: { type: Object as () => WidgetState, required: true },
    query: String,
    fragment: String,
  },
  setup(props) {
    watchEffect(cleanup => {
      const title = new URLSearchParams(props.query).get("title")
      if (title) {
        props.widget.title = title
        cleanup(() => props.widget.title = undefined)
      }
    })
    return () =>
      <div class="p-4 text-zinc-500 dark:text-zinc-400">
        <h1 class="text-xl mb-4">Widget Not Found</h1>
        <div class="font-mono">
          <p>uri: {props.widget?.uri}</p>
          <p>query: {props.query}</p>
          <p>fragment: {props.fragment}</p>
          <p>tabId: {props.widget?.bindingTab}</p>
        </div>
      </div>
  },
})
