
export const allowedWriteExt = ".maine-cat"

export type FSPath = string[]

export interface FileEntry {
  name: string
  type: "file"
  revision?: string
  size?: number
  lastModified?: string
}

export interface DirectoryEntry {
  name: string
  type: "directory"
  size?: number
  files?: number
  lastModified?: string
}

export type FSEntry = FileEntry | DirectoryEntry

export interface FileSystemState {
  id: string
  type: string
  name: string
}

/**
 * A very simple file system
 */
export interface AbstractFileSystem extends FileSystemState {
  available: boolean
  listDir(dirname: FSPath): Promise<FSEntry[]>
  readFile(filename: FSPath): Promise<Blob>
  writeFile(filename: FSPath, data: Blob): Promise<void>
}

export interface FileSystemFactory {
  type: string
  requestNew(): Promise<[state: FileSystemState, fs: AbstractFileSystem]>
  requestLast(state: any): Promise<[state: FileSystemState, fs: AbstractFileSystem]>
}
