
let lastTime: number | undefined = undefined
let counter = 0

export function cuid() {
  const time = Date.now()
  if (time !== lastTime) counter = 0
  else counter++
  lastTime = time
  const timeStr = time.toString(36).padStart(9, "0")
  const counterStr = counter.toString(36).padStart(2, "0")
  const random = Math.random().toString(36).substring(2, 6)
  return `c${timeStr}${counterStr}${random}`
}

export function getDate(id: string) {
  return new Date(parseInt(id.slice(1, 10), 36))
}
