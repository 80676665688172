
export const notification = {
  no: "No notifications",
  dismiss: "Dismiss",
  dismiss_all: "Dismiss All",
  confirm: "Confirm",
  detail: "Detail",

  offline_available: "Application is offline available",
  update_installed: "Available update is installed",
  confirm_to_activate: "Activate update immediately?",
}
