import { defineComponent, ref } from "vue"
import { useI18n } from "@maine-cat/i18n"
import { Button, Dialog, Typography } from "@maine-cat/components"
import { XCircleIcon } from "@heroicons/vue/24/solid"
import { Diagnostics } from "./Diagnostics"

const MoreInfo = defineComponent({
  name: "MoreInfo",
  setup() {
    const { t } = useI18n()
    const open = ref(false)
    const show = () => open.value = true
    const close = () => open.value = false
    return () => <>
      <Button onClick={show}>{t("diagnostic.more_information")}</Button>
      <Dialog
        open={open.value} onClose={close}
        v-slots={{
          content: () => <Diagnostics showExitButton />,
          actions: () => <Button onClick={close}>{t("close")}</Button>,
        }}
      />
    </>
  },
})

export const NotSupportedPage = defineComponent({
  name: "NotSupportedPage",
  setup() {
    const { t } = useI18n()
    return () =>
      <div class="h-screen bg-zinc-50 dark:bg-zinc-800 overflow-hidden flex items-center justify-center">
        <div class="p-6 mx-4 max-w-[600px] bg-white dark:bg-zinc-700 shadow-md rounded space-y-4">
          <div class="flex items-center">
            <Typography is="h2" class="flex items-center">
              <XCircleIcon class="inline w-6 h-6 mr-2" />
              {t("diagnostic.unsupported_browser")}
            </Typography>
          </div>
          <Typography silent>
            {t("diagnostic.unsupported_message")}
          </Typography>
          <div class="flex items-center justify-center m-8">
            <MoreInfo />
          </div>
        </div>
      </div>
  },
})
