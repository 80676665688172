import { Teleport, defineComponent, reactive } from "vue"
import { WidgetState, renderWidgetByUri, useWidgetService } from "@maine-cat/service/widget"
import { NotFoundWidget } from "./NotFoundWidget"

export const WidgetRenderer = defineComponent({
  name: "WidgetRenderer",
  props: {
    tabId: { type: String, required: true },
    uri: { type: String, required: true },
  },
  setup(props) {
    const { useWidgetManagement } = useWidgetService()
    const rootEl = document.createElement("div")
    rootEl.className = "WidgetRenderer absolute inset-0 overflow-auto"

    const widget = reactive<WidgetState>({
      get bindingTab() { return props.tabId },
      get uri() { return props.uri },
      get rootElement() { return rootEl },
    })

    useWidgetManagement(widget)

    return () =>
      <Teleport to={rootEl}>
        {renderWidgetByUri(widget, NotFoundWidget)}
      </Teleport>
  },
})
