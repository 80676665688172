import { HTMLAttributes } from "vue"
import { arrayEqual } from "@maine-cat/common/utils"
import { LayoutPath, SplitProps, TabProps, useLayoutService } from "../layout"
import { DragAndDropState, useDnDService } from "./dnd"

export function useDragTab(props: { path: LayoutPath, index: number, tab: TabProps }) {
  const { dndState } = useDnDService()
  const { atomics } = useLayoutService()

  const handleProps: HTMLAttributes = {
    draggable: true,
    onDragstart(e) {
      dndState.dragging = {
        tab: props.tab,
        source: { path: props.path, index: props.index },
      }
      document.addEventListener("dragend", () => {
        dndState.dragging = undefined
        dndState.dropping = undefined
      }, { once: true })
    },
    onDragover(e) {
      const { dragging } = dndState
      if (!dragging) return
      e.preventDefault()
      e.stopPropagation()
      e.dataTransfer!.dropEffect = "move"
      dndState.dropping = {
        path: props.path,
        index: props.index,
        droppingElement: e.currentTarget as HTMLElement,
      }
    },
    onDragleave() {
      dndState.dropping = undefined
    },
    onDrop(e) {
      handleDrop(dndState, atomics, e)
    },
  }
  return handleProps
}

export function useDropLayout(
  props: { path: LayoutPath },
  noSplitElements?: string
) {
  const { dndState } = useDnDService()
  const { atomics } = useLayoutService()

  const layoutProps: HTMLAttributes = {
    onDragover(e) {
      const { dragging } = dndState
      if (!dragging) return
      e.preventDefault()
      e.stopPropagation()
      e.dataTransfer!.dropEffect = "move"

      // no split when dropping on tab bar
      if (noSplitElements && (e.target as HTMLElement).matches(noSplitElements)) {
        dndState.dropping = {
          path: props.path,
          droppingElement: e.target as HTMLElement,
        }
        return
      }

      dndState.dropping = {
        path: props.path,
        droppingElement: e.currentTarget as HTMLElement,
        split: getSplitProps((e.currentTarget as HTMLElement).getBoundingClientRect(), e),
      }
    },
    onDragleave() {
      dndState.dropping = undefined
    },
    onDrop(e) {
      handleDrop(dndState, atomics, e)
    },
  }
  return layoutProps
}

function handleDrop(
  dnd: DragAndDropState,
  atomics: ReturnType<typeof useLayoutService>["atomics"],
  e: DragEvent,
) {
  const { dragging, dropping } = dnd
  dnd.dragging = undefined
  dnd.dropping = undefined
  if (!dragging || !dropping) return
  e.stopPropagation()
  e.preventDefault()

  if (!dragging.source) {
    if (!dropping.split) {
      atomics.openTab({
        path: dropping.path, tab: dragging.tab, index: dropping.index,
      })
    }
    else {
      atomics.openTab({
        path: dropping.path, tab: dragging.tab,
        splitProps: dropping.split, split: dropping.split.bias,
      })
    }
  }
  else {
    if (arrayEqual(dragging.source.path, dropping.path) && dropping.index === undefined && !dropping.split)
      return

    if (!dropping.split) {
      atomics.moveTab({
        path: dragging.source.path, index: dragging.source.index,
        to: dropping.path, toIndex: dropping.index,
      })
    }
    else {
      atomics.moveTab({
        path: dragging.source.path, index: dragging.source.index,
        to: dropping.path,
        splitProps: dropping.split, split: dropping.split.bias,
      })
    }
  }
}

function getSplitProps(rect: DOMRect, e: MouseEvent): SplitProps | undefined {
  const x = (e.clientX - rect.left) / rect.width
  const y = (e.clientY - rect.top) / rect.height

  if (x < 0.25)
    return { direction: "horizontal", bias: "before" }
  if (x > 0.75)
    return { direction: "horizontal", bias: "after" }

  if (y < 0.25)
    return { direction: "vertical", bias: "before" }
  if (y > 0.75)
    return { direction: "vertical", bias: "after" }

  return undefined
}
