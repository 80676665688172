import { defineComponent, h, unref } from "vue"
import { Button, LinearProgress } from "@maine-cat/components"
import { NotificationEntry, useNotificationService } from "@maine-cat/service/notification"
import { ArrowTopRightOnSquareIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline"

export const NotificationItem = defineComponent({
  name: "NotificationItem",
  props: {
    item: { type: Object as () => NotificationEntry, required: true },
  },
  setup(props) {
    const { close, openState } = useNotificationService()

    const dismiss = () => close(props.item.id!, false)
    const confirm = () => close(props.item.id!, true)
    const detail = () => { openState.value = false; props.item.onDetail?.() }

    return () => {
      const { item } = props
      const title = unref(item.title)
      const content = unref(item.content)
      const icon = unref(item.icon)
      const progress = unref(item.progress)

      const actions = item.renderActions ? item.renderActions() : <>
        {item.onDetail &&
          <Button onClick={detail} size="icon" class="p-1 rounded-lg" color="info">
            {unref(item.detailText) || <ArrowTopRightOnSquareIcon class="h-4 w-4" />}
          </Button>}
        {item.onConfirm &&
          <Button onClick={confirm} size="icon" class="p-1 rounded-lg" color="primary">
            {unref(item.confirmText) || <CheckIcon class="h-4 w-4" />}
          </Button>}
        <Button onClick={dismiss} size="icon" class="p-1 rounded-lg" color={item.onConfirm ? "secondary" : "primary"}>
          {unref(item.dismissText) || <XMarkIcon class="h-4 w-4" />}
        </Button>
      </>

      return (
        <div class="relative px-4 py-2">
          <div class={[
            "absolute left-0 top-0 bottom-0 w-1 bg-var-400",
            item.color && `color-${item.color}`,
          ]}></div>
          {progress &&
            <LinearProgress class="absolute top-0 inset-x-0 h-[2px] color-primary"
              value={progress === true ? 1 : progress} />}
          {item.renderContent ? item.renderContent() : <>
            <div class="flex">
              {icon && h(icon, { class: "w-4 h-4 mt-1 mr-2" })}
              <div class="pb-1">
                {title && <h2 class="leading-8">{title}</h2>}
                {content && <p class="text-sm text-zinc-600 dark:text-zinc-300">{content}</p>}
              </div>
            </div>
          </>}
          <div class="flex items-center justify-end space-x-2">
            {actions}
          </div>
        </div>
      )
    }
  },
})
