import { never } from "../cancellation"

export async function generateVerifierAndChallenge() {
  const verifierBytes = crypto.getRandomValues(new Uint8Array(32))
  const verifier = encodeBase64url(verifierBytes)

  const encoder = new TextEncoder()
  const hashBuffer = await crypto.subtle.digest("SHA-256", encoder.encode(verifier))
  const challenge = encodeBase64url(new Uint8Array(hashBuffer))

  return { verifier, challenge, challenge_method: "S256" }
}

export function encodeBase64url(buffer: ArrayLike<number>) {
  let base64 = ""
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_"

  for (let i = 0; i < buffer.length; i += 3) {
    const byte1 = buffer[i]
    const byte2 = buffer[i + 1]
    const byte3 = buffer[i + 2]

    const char1 = byte1 >> 2
    const char2 = ((byte1 & 3) << 4) | (byte2 >> 4)
    const char3 = ((byte2 & 15) << 2) | (byte3 >> 6)
    const char4 = byte3 & 63

    if (i + 1 === buffer.length)
      base64 += chars[char1] + chars[char2]
    else if (i + 2 === buffer.length)
      base64 += chars[char1] + chars[char2] + chars[char3]
    else
      base64 += chars[char1] + chars[char2] + chars[char3] + chars[char4]
  }

  return base64
}

export function handleOAuthCode() {
  const url = new URL(window.location.href)
  const code = url.searchParams.get("code")
  const error = url.searchParams.get("error")
  const state = url.searchParams.get("state")
  if (code || error) {
    const bc = new BroadcastChannel("oauth-callback-" + state)
    bc.postMessage({ code, error, state })
    document.getElementById("app")!.innerText = "Loading..."
    bc.onmessage = e => {
      if (e.data.close)
        window.close()
    }
    setTimeout(() => window.close(), 3000)
    return never()
  }

  return Promise.resolve()
}

export function openOAuthModal(url: string, state: string) {

  const height = window.innerHeight / 3 * 2
  const width = 600

  const left = window.innerWidth / 2 - width / 2
  const top = window.innerHeight / 2 - height / 2

  window.open(
    url,
    "OAuth Popup",
    `width=${width}, height=${height}, top=${top}, left=${left}, scrollbars=yes, resizable=yes`
  )

  return new Promise<string>((res, rej) => {
    const bc = new BroadcastChannel("oauth-callback-" + state)

    bc.onmessage = e => {
      if (e.data.error)
        rej(e.data.error)
      else if (e.data.code)
        res(e.data.code)

      bc.postMessage({ close: true })
      bc.close()
    }

    setTimeout(() => {
      bc.close()
      rej(new Error("Timeout"))
    }, 120000)
  })
}
