import { VNode, unref } from "vue"
import { ByPath, ToPaths, TransArgs, TransResult, Translation } from "./types"

export type { Translation }

export interface I18nFunction<T> {
  <K extends ToPaths<T, keyof T>>(key: K, ...args: TransArgs<ByPath<T, K>>): TransResult<ByPath<T, K>>

  /** @deprecated Maybe a mis-spelt entry */
  (key: string, ...args: {}[]): string | VNode

  /** Force fallback to undefined if key is not defined in translations */
  (key: string, fallback: undefined): string | undefined

  /** Force to translate in a specified language */
  as(lang: string): this
}

export function createI18n<T extends Translation, Language extends string>(
  translations: Record<Language, DeepPartial<T>>,
  language: MaybeRef<Language>,
  fallback: Language
): I18nFunction<T> {
  const flattened: Record<string, Record<string, string | Function>> = {}

  for (const [lang, trans] of Object.entries(translations))
    flattened[lang] = Object.fromEntries(entries(trans))

  const t = function t(this: any, key: string, ...args: any[]) {
    const lang = typeof this === "string" ? this : unref(language)

    const entry = flattened[lang]?.[key] || flattened[fallback][key]
    if (typeof entry === "string")
      return entry

    if (typeof entry === "function")
      return entry.call(undefined, ...args as any[])

    if (args.length === 1 && args[0] === undefined)
      return undefined

    return key
  } as I18nFunction<T>

  t.as = function as(lang: string) {
    return t.bind(lang)
  }

  return t
}

function entries(obj: any, prefix = ""): [string, any][] {
  return Object.entries(obj).flatMap(([key, value]) =>
    typeof value === "object"
      ? entries(value, prefix ? `${prefix}.${key}` : key)
      : [[prefix ? `${prefix}.${key}` : key, value]])
}
