import { cuid } from "../cuid"
import { generateVerifierAndChallenge, openOAuthModal } from "./oauth"
import { AbstractFileSystem, FSEntry, FileSystemFactory, allowedWriteExt } from "./types"

const type = "onedrive"
const tenant = "common"
const client_id = import.meta.env.VITE_ONEDRIVE_CLIENT_ID

interface OnedriveHistory {
  id: string
  type: typeof type
  name: string
  refreshKey: string
}

export const onedriveFactory: FileSystemFactory = {
  type,
  async requestNew() {
    const id = cuid()
    const { verifier, challenge, challenge_method } = await generateVerifierAndChallenge()

    if (!client_id)
      throw new Error("No client id configured for OneDrive")

    const search = new URLSearchParams({
      client_id,
      response_type: "code",
      redirect_uri: location.origin,
      response_mode: "query",
      scope: "offline_access Files.ReadWrite.All",
      code_challenge: challenge,
      code_challenge_method: challenge_method,
      state: id,
    })

    const url = new URL(`https://login.microsoftonline.com/${tenant}/oauth2/v2.0/authorize?${search}`)

    const code = await openOAuthModal(url.toString(), id)

    const body = new URLSearchParams({
      client_id,
      grant_type: "authorization_code",
      code,
      code_verifier: verifier,
      redirect_uri: location.origin,
    })

    const [accessKey, refreshKey] = await fetchTokens(body)

    const history = { id, type, name: "OneDrive", refreshKey }
    return [history, makeFS(id, accessKey)]
  },

  async requestLast(history: OnedriveHistory) {
    if (!client_id)
      throw new Error("No client id configured for OneDrive")

    const body = new URLSearchParams({
      client_id,
      grant_type: "refresh_token",
      refresh_token: history.refreshKey,
    })

    const [accessKey, refreshKey] = await fetchTokens(body)

    const { id, name } = history
    return [{ id, type, name, refreshKey }, makeFS(id, accessKey)]
  },
}

const sharedDir = "[Shared with me]"

function makeFS(id: string, accessKey: string): AbstractFileSystem {

  const headers = {
    "Authorization": `Bearer ${accessKey}`,
  }

  const sharedCache: Record<string, [driveId: string, id: string]> = {}

  const requestList = async (url: string, handleItem?: (item: any) => void) => {
    const res = await fetch(url, { headers }).then(res => res.json())
    if ("error" in res)
      throw new Error(`OneDrive failed with error ${JSON.stringify(res)}`)

    const entries: FSEntry[] = (res.value as any[]).map(x => {
      handleItem?.(x)
      return {
        name: x.name,
        type: x.folder ? "directory" : "file",
        size: x.size,
        lastModified: x.lastModifiedDateTime,
      }
    })
    return entries
  }

  return {
    id,
    type,
    name: "OneDrive",
    available: true,
    async listDir(dirname) {
      if (dirname.length && dirname[0] === sharedDir) {
        // handle root of shared dir
        if (dirname.length === 1) {
          const entries = await requestList("https://graph.microsoft.com/v1.0/me/drive/sharedWithMe", item => {
            sharedCache[item.name] = [item.remoteItem.parentReference.driveId, item.remoteItem.id]
            Object.assign(item, item.remoteItem)
          })
          return entries
        }

        // handle remote items in shared dir
        const path = dirname.slice(1).join("/")
        const [driveId, id] = sharedCache[path] || []
        if (!driveId || !id)
          throw new Error(`No driveId or id for path ${path}`)

        const entries = await requestList(`https://graph.microsoft.com/v1.0/drives/${driveId}/items/${id}/children`, item => {
          const fullPath = `${path}/${item.name}`
          sharedCache[fullPath] = [driveId, item.id]
        })
        return entries
      }

      // handle root of personal drive
      const url = !dirname.length
        ? "https://graph.microsoft.com/v1.0/me/drive/root/children"
        : `https://graph.microsoft.com/v1.0/me/drive/root:/${dirname.join("/")}:/children`

      const entries = await requestList(url)
      if (!dirname.length) {
        entries.push({
          name: sharedDir,
          type: "directory",
        })
      }
      return entries
    },
    async readFile(filename) {
      if (filename.length <= 0)
        throw new Error("Unexpected file name")

      let url = `https://graph.microsoft.com/v1.0/me/drive/root:/${filename.join("/")}:/content`

      // handle shared files
      if (filename[0] === sharedDir) {
        if (filename.length <= 1)
          throw new Error("Unexpected file name")

        const path = filename.slice(1).join("/")
        const [driveId, id] = sharedCache[path] || []
        if (!driveId || !id)
          throw new Error(`No driveId or id for path ${path}`)

        url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${id}/content`
      }

      const res = await fetch(url, { headers })
      if (!res.ok)
        throw new Error(`OneDrive failed with error ${res.status} ${res.statusText}`)

      return await res.blob()
    },
    async writeFile(filename, data) {
      if (filename.length <= 0)
        throw new Error("Unexpected file name")

      if (!filename[filename.length - 1].endsWith(allowedWriteExt))
        throw new Error(`File name to write must end with ${allowedWriteExt}`)

      let url = `https://graph.microsoft.com/v1.0/me/drive/root:/${filename.join("/")}:/content`

      // handle shared files
      if (filename[0] === sharedDir) {
        if (filename.length <= 1)
          throw new Error("Unexpected file name")

        const name = filename.pop()!
        const path = filename.slice(1).join("/")
        const [driveId, id] = sharedCache[path] || []
        if (!driveId || !id)
          throw new Error(`No driveId or id for path ${path}`)

        url = `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${id}:${name}:/content`
      }

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/octet-stream",
        },
        body: data,
      })

      if (!res.ok)
        throw new Error(`OneDrive failed with error ${res.status} ${res.statusText}`)
    },
  }
}

async function fetchTokens(body: URLSearchParams) {
  const response = await fetch(`https://login.microsoftonline.com/${tenant}/oauth2/v2.0/token`, {
    method: "POST", body,
  }).then(res => res.json())

  if ("error" in response)
    throw new Error(`OAuth failed with error ${JSON.stringify(response)}`)

  const accessKey = response.access_token
  const refreshKey = response.refresh_token

  return [accessKey, refreshKey] as [string, string]
}
