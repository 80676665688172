import { computed, ref, shallowRef, watch } from "vue"
import { defineService } from "@maine-cat/common/service"
import { cuid } from "@maine-cat/common/cuid"
import { useSpotlightService } from "../spotlight"
import { useCommandService } from "../command"
import { WorkspaceLayoutProps } from "./types"
import * as actions from "./actions"
import { getUriWidget } from "../widget"

export const useLayoutService = defineService({
  id: "layout",
  setup() {
    const { useCommand } = useCommandService()
    const { open } = useSpotlightService()

    const layout = shallowRef<WorkspaceLayoutProps>({
      type: "tabs",
      tabs: [{
        id: cuid(),
        title: "Home",
        viewUri: "widget:home",
      }],
      currentTab: 0,
    })

    const tabs = computed(() => actions.flattenTabs(layout.value))

    // should be frequently updated by onFocusIn event
    const lastFocusTab = ref<string>()

    // newest to oldest
    let observedLastFocusTab: string[] = []
    watch(lastFocusTab, lastFocus => {
      if (lastFocus) {
        const tabIds = tabs.value.map(x => x.id)
        observedLastFocusTab = [
          lastFocus,
          ...observedLastFocusTab.filter(x =>
            tabIds.includes(x) && x !== lastFocusTab.value),
        ]
      }
    })

    function bindLayout<T>(action: (layout: WorkspaceLayoutProps, params: T) => WorkspaceLayoutProps) {
      return (params: T) => layout.value = action(layout.value, params)
    }

    const atomics = {
      openTab: bindLayout(actions.openTab),
      moveTab: bindLayout(actions.moveTab),
      closeTab: bindLayout(actions.closeTab),
      focusTab: bindLayout(actions.focusTab),
      adjustSplit: bindLayout(actions.adjustSplit),
      updateTab: bindLayout(actions.updateTab),
    }

    function findPathForWidgetType(type: string) {
      for (const id of observedLastFocusTab) {
        const tab = tabs.value.find(x => x.id === id)
        if (tab && getUriWidget(tab.viewUri) === type)
          return actions.findTabPathById(layout.value, id)[0]
      }

      const lastSameType = tabs.value.findLast(x => getUriWidget(x.viewUri) === type)
      if (lastSameType)
        return actions.findTabPathById(layout.value, lastSameType.id)[0]

      const lastTab = tabs.value[tabs.value.length - 1]
      if (lastTab)
        return actions.findTabPathById(layout.value, lastTab.id)[0]

      return undefined
    }

    const openNewTab = useCommand<{ uri: string, title?: string }>({
      id: "layout.openTab",
      condition: () => true,
      async resolveParameters({ uri }) {
        uri ??= await new Promise<string>(res => {
          open({
            type: "input",
            defaultInput: "widget:",
            placeholder: "Enter widget uri",
            onAccept: res,
          })
        })
        return { uri }
      },
      handler({ uri, title }) {
        const type = getUriWidget(uri)
        if (!type)
          throw new Error(`Invalid widget uri: ${uri}`)

        const id = cuid()
        const path = findPathForWidgetType(type) || []
        atomics.openTab({
          path,
          tab: {
            id,
            title: title || type,
            viewUri: uri,
          },
        })
        lastFocusTab.value = id
      },
      display: true,
    })

    const focusTab = useCommand<{ tabId: string }>({
      id: "layout.focusTab",
      condition: () => true,
      async resolveParameters({ tabId }) {
        tabId ??= await new Promise<string>(res => {
          open({
            type: "select",
            items: tabs.value.map(tab => ({
              key: tab.id,
              title: tab.title,
            })),
            onAccept: item => res(item.key),
          })
        })
        return { tabId }
      },
      handler({ tabId }) {
        const [path, index] = actions.findTabPathById(layout.value, tabId)
        if (!path)
          return
        atomics.focusTab({ path, index })
        lastFocusTab.value = tabId
      },
    })

    return { layout, tabs, lastFocusTab, openNewTab, focusTab, atomics }
  },
})
