import { Dialog, FilledInput, VirtualScroll } from "@maine-cat/components"
import { defineComponent, ref } from "vue"
import { SpotlightItem } from "./SpotlightItem"
import { useCommandsSpotlight, useSpotlightService } from "@maine-cat/service/spotlight"

export const Spotlight = defineComponent({
  name: "Spotlight",
  setup() {
    const {
      state, action, cancel,
      input, activeIndex, searchResultList, optionsList,
    } = useSpotlightService()

    useCommandsSpotlight()

    const onKeydown = (e: KeyboardEvent) => {
      if (!state.value)
        return
      if (e.key === "Enter") {
        e.preventDefault()
        action()
      }
      else if (state.value.option.type === "select") {
        if (e.key === "ArrowUp") {
          e.preventDefault()
          activeIndex.value = Math.max(0, activeIndex.value - 1)
          virtualScroll.value?.scrollToIndex(activeIndex.value, false)
        }
        else if (e.key === "ArrowDown") {
          e.preventDefault()
          const itemsLength = (searchResultList.value || optionsList.value).length
          activeIndex.value = Math.min(itemsLength - 1, activeIndex.value + 1)
          virtualScroll.value?.scrollToIndex(activeIndex.value, false)
        }
      }
    }

    const virtualScroll = ref<{ scrollToIndex(index: number, smooth?: boolean): void }>()

    return () =>
      <Dialog
        open={!!state.value}
        onClose={cancel}
        containerClass="flex flex-col items-center justify-start p-8 pt-[15vh]"
        noBackdrop>
        <div class="m-4">
          <FilledInput
            v-model={input.value}
            placeholder={state.value?.option.placeholder}
            type={state.value?.option.type === "input" &&
              state.value.option.password ? "password" : undefined}
            fullWidth
            onKeydown={onKeydown} />
          <VirtualScroll
            ref={virtualScroll}
            key={state.value?.id || ""}
            class="min-h-[32px] max-h-[50vh]"
            list={searchResultList.value || optionsList.value}
            renderComponent={SpotlightItem} />
        </div>
      </Dialog>
  },
})
