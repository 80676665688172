import { arrayEqual, splice } from "@maine-cat/common/utils"
import { LayoutPath, SplitDirection, SplitProps, TabProps, WorkspaceLayoutProps } from "./types"

export interface LayoutActionPanelParams {
  /**
   * The path in layout
   */
  path: LayoutPath
  /**
   * The index of tab
   */
  index?: number
}

export interface LayoutActionTabParams extends LayoutActionPanelParams {
  /**
   * The index of tab
   */
  index: number
}

export interface LayoutSplitParams {
  /**
   * Request a split
   */
  split?: SplitDirection
  /**
   * Split props
   */
  splitProps?: SplitProps
}

export interface LayoutOpenTabParams extends LayoutActionPanelParams, LayoutSplitParams {
  /**
   * The tab to open
   */
  tab: TabProps
  /**
   * Set focus to the tab
   */
  focus?: boolean
}

export interface LayoutMoveTabParams extends LayoutActionTabParams, LayoutSplitParams {
  /**
   * The target path
   */
  to: LayoutPath;
  /**
   * The target index
   */
  toIndex?: number;
}

export interface LayoutSplitAdjustParams {
  /**
   * The layout path
   */
  path: LayoutPath
  /**
   * The split value
   */
  value: number
  /**
   * The new splitProps
   */
  splitProps?: SplitProps
}

export interface LayoutUpdateTabParams extends LayoutActionTabParams {
  /**
   * the tab props to update
   */
  tabProps: Partial<TabProps>
}

function unwrapEmpty(layout: WorkspaceLayoutProps): WorkspaceLayoutProps {
  if (layout.type === "split") {
    if (layout.before.type === "empty")
      return layout.after
    if (layout.after.type === "empty")
      return layout.before
  }
  else if (layout.type === "tabs") {
    if (!layout.tabs.length)
      return { type: "empty" }
  }
  return layout
}

export function findLayout(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
): WorkspaceLayoutProps {
  if (!path.length)
    return layout

  if (layout.type !== "split")
    throw new Error("Recursing non-split layout")

  const [next, ...rest] = path
  return findLayout(layout[next], rest)
}

export function findTabByPath(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  index: number,
): TabProps {
  if (path.length) {
    if (layout.type !== "split")
      throw new Error("Recursing non-split layout")

    const [next, ...rest] = path
    return findTabByPath(layout[next], rest, index)
  }

  if (layout.type !== "tabs")
    throw new Error("Finding tab to a non-tab layout")
  if (index < 0 || index >= layout.tabs.length)
    throw new Error("Finding tab index out of range")

  return layout.tabs[index]
}

function findTabIdRecurse(layout: WorkspaceLayoutProps, base: LayoutPath, id: string): [LayoutPath, number, TabProps] | null {
  if (layout.type === "split") {
    return (
      findTabIdRecurse(layout.before, [...base, "before"], id) ||
      findTabIdRecurse(layout.after, [...base, "after"], id)
    )
  }
  if (layout.type === "tabs") {
    const index = layout.tabs.findIndex(x => x.id === id)
    if (index >= 0)
      return [base, index, layout.tabs[index]]
  }
  return null
}

export function findTabPathById(layout: WorkspaceLayoutProps, id: string) {
  return findTabIdRecurse(layout, [], id) || [null, null, null]
}

export function flattenTabs(layout: WorkspaceLayoutProps): TabProps[] {
  if (layout.type === "split")
    return [layout.before, layout.after].flatMap(flattenTabs)

  if (layout.type === "tabs")
    return layout.tabs

  return []
}

function recurseSplit(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  recurse: (layout: WorkspaceLayoutProps, rest: LayoutPath) => WorkspaceLayoutProps
) {
  if (!path.length)
    throw new Error("Recursing empty path")
  if (layout.type !== "split")
    throw new Error("Recursing non-split layout")

  const [next, ...rest] = path
  const result = unwrapEmpty(recurse(layout[next], rest))
  if (result === layout[next])
    return layout

  return unwrapEmpty({
    ...layout,
    [next]: result,
  })
}

function atomicAddTab(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  tab: TabProps,
  index?: number,
  focus?: boolean,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicAddTab(layout, path, tab, index, focus))

  if (layout.type === "empty")
    return { type: "tabs", tabs: [tab], currentTab: 0 }

  if (layout.type !== "tabs")
    throw new Error("Adding tab to a non-tab layout")

  index ??= layout.tabs.length
  const currentTab =
    focus
      ? index
      : index <= layout.currentTab
        ? layout.currentTab + 1
        : layout.currentTab
  return {
    ...layout,
    tabs: splice(layout.tabs, index, 0, tab),
    currentTab,
  }
}

function atomicFocusTab(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  index: number,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicFocusTab(layout, path, index))

  if (layout.type !== "tabs")
    throw new Error("Focusing tab to a non-tab layout")

  return {
    ...layout,
    currentTab: index,
  }
}

function atomicAdjustSplit(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  value: number,
  splitProps?: SplitProps,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicAdjustSplit(layout, path, value, splitProps))

  if (layout.type !== "split")
    throw new Error("Adjusting split in a non-split layout")

  return {
    ...layout,
    splitValue: value,
    ...splitProps ? { splitProps } : {},
  }
}

function atomicRemoveTab(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  index: number,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicRemoveTab(layout, path, index))

  if (layout.type !== "tabs")
    throw new Error("Removing tab in a non-tab layout")

  const currentTab =
    layout.currentTab > 0 && index <= layout.currentTab
      ? layout.currentTab - 1
      : layout.currentTab
  return unwrapEmpty({
    ...layout,
    tabs: splice(layout.tabs, index, 1),
    currentTab,
  })
}

function atomicMoveTabInTabs(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  index: number,
  toIndex?: number,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicMoveTabInTabs(layout, path, index, toIndex))

  if (layout.type !== "tabs")
    throw new Error("Moving tab in a non-tab layout")

  toIndex ??= layout.tabs.length - 1
  if (index === toIndex ||
    index >= layout.tabs.length ||
    toIndex >= layout.tabs.length)
    return layout

  const tab = layout.tabs[index]
  const tabs = splice(splice(layout.tabs, index, 1), toIndex, 0, tab)

  const prevTab = layout.currentTab
  const currentTab =
    prevTab === index
      ? toIndex
      : index < prevTab && prevTab <= toIndex
        ? prevTab - 1
        : index > prevTab && prevTab >= toIndex
          ? prevTab + 1
          : prevTab
  return {
    ...layout,
    tabs,
    currentTab,
  }
}

function atomicCreateSplit(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  tab: TabProps,
  bias: SplitDirection = "after",
  splitProps: SplitProps = {}
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicCreateSplit(layout, path, tab, bias, splitProps))

  let before: WorkspaceLayoutProps = { type: "tabs", tabs: [tab], currentTab: 0 }
  let after = layout
  if (bias === "after")
    [before, after] = [after, before]

  const splitValue = splitProps.limit ? (splitProps.limit[0] + splitProps.limit[1]) / 2 : 50
  return {
    type: "split",
    splitProps,
    splitValue,
    before,
    after,
  }
}

function atomicUpdateTabProps(
  layout: WorkspaceLayoutProps,
  path: LayoutPath,
  index: number,
  tabProps: Partial<TabProps>,
): WorkspaceLayoutProps {
  if (path.length)
    return recurseSplit(layout, path, (layout, path) => atomicUpdateTabProps(layout, path, index, tabProps))

  if (layout.type !== "tabs")
    throw new Error("Moving tab in a non-tab layout")

  if (index < 0 || index >= layout.tabs.length)
    throw new Error("Updating tab index out of range")

  const tab = layout.tabs[index]
  const newTab = { ...tab, ...tabProps }
  return {
    ...layout,
    tabs: splice(layout.tabs, index, 1, newTab),
  }
}

export function openTab(
  layout: WorkspaceLayoutProps,
  { path, index, tab, split, splitProps, focus = true }: LayoutOpenTabParams
): WorkspaceLayoutProps {
  if (!split)
    return atomicAddTab(layout, path, tab, index, focus)

  return atomicCreateSplit(layout, path, tab, split, splitProps)
}

export function moveTab(
  layout: WorkspaceLayoutProps,
  { path, index, to, toIndex, split, splitProps }: LayoutMoveTabParams
): WorkspaceLayoutProps {

  const tab = findTabByPath(layout, path, index)

  if (!split) {
    if (arrayEqual(path, to))
      return atomicMoveTabInTabs(layout, path, index, toIndex)

    // first add the tab to layout to prevent path change
    layout = atomicAddTab(layout, to, tab, toIndex)
    return atomicRemoveTab(layout, path, index)
  }

  if (arrayEqual(path, to)) {
    // will create a new split at path,
    // so extend path to the other split
    path = [...path, split === "before" ? "after" : "before"]
  }

  // first add the tab to layout to prevent path change
  layout = atomicCreateSplit(layout, to, tab, split, splitProps)
  return atomicRemoveTab(layout, path, index)
}

export function closeTab(
  layout: WorkspaceLayoutProps,
  { path, index }: LayoutActionTabParams
) {
  return atomicRemoveTab(layout, path, index)
}

export function focusTab(
  layout: WorkspaceLayoutProps,
  { path, index }: LayoutActionTabParams
) {
  return atomicFocusTab(layout, path, index)
}

export function adjustSplit(
  layout: WorkspaceLayoutProps,
  { path, value, splitProps }: LayoutSplitAdjustParams
) {
  return atomicAdjustSplit(layout, path, value, splitProps)
}

export function updateTab(
  layout: WorkspaceLayoutProps,
  { path, index, tabProps }: LayoutUpdateTabParams
) {
  return atomicUpdateTabProps(layout, path, index, tabProps)
}
