import "./index.css"
import { createApp } from "vue"
import { register } from "./serviceWorker/register"
import { App } from "./App"
import { handleOAuthCode } from "@maine-cat/common/filesystem/oauth"

register()

handleOAuthCode().then(() =>
  createApp(App).mount("#app"))
