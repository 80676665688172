import { defineComponent } from "vue"
import { FeatureEntry, features } from "@maine-cat/common/caniuse"
import { useI18n } from "@maine-cat/i18n"
import { ExternalLink, Typography } from "@maine-cat/components"
import { CheckCircleIcon, MinusCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline"
import { CubeIcon } from "@heroicons/vue/24/solid"

export const CaniuseFeature = defineComponent({
  name: "CaniuseFeature",
  props: {
    feature: {
      type: Object as () => FeatureEntry,
      required: true,
    },
  },
  setup(props) {
    return () => {
      const { id, required, externalURL, check } = props.feature
      return (
        <div class="flex items-center">
          {check ? <CheckCircleIcon class="h-6 w-6 mr-4 color-success text-var-500" />
          : required ? <XCircleIcon class="h-6 w-6 mr-4 color-error text-var-500" />
          : <MinusCircleIcon class="h-6 w-6 mr-4 color-warn text-var-500" />}
          <ExternalLink href={externalURL}>{id}</ExternalLink>
          <span class="sr-only">
            {": "}{required ? "required" : "optional"}
            {" and "}{check ? "satisfied" : "unsatisfied"}
          </span>
        </div>
      )
    }
  },
})

export const CaniuseTable = defineComponent({
  name: "CaniuseTable",
  setup() {
    const { t } = useI18n()
    return () =>
      <div>
        <Typography is="h3" class="flex items-center mb-6">
          <CubeIcon class="w-6 h-6 mr-4" />
          {t("diagnostic.features_check")}
        </Typography>
        <div class="space-y-2 pl-4">
          {features.map(f => <CaniuseFeature feature={f} key={f.id} />)}
        </div>
      </div>
  },
})
