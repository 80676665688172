import { defineComponent, unref, watchEffect } from "vue"
import { Tab, Tabs, useUncontrolled } from "@maine-cat/components"
import { LayoutPath, TabProps, TabsLayoutProps, useLayoutService } from "@maine-cat/service/layout"
import { useWidgetService } from "@maine-cat/service/widget"
import { useDragTab, useDropLayout } from "@maine-cat/service/dnd"
import { TabWidgetView } from "@maine-cat/app/widget/TabWidgetView"
import { XMarkIcon } from "@heroicons/vue/24/outline"

const DragTab = defineComponent({
  name: "DragTab",
  props: {
    layout: { type: Object as () => TabsLayoutProps, required: true },
    path: { type: Array as () => LayoutPath, required: true },
    tab: { type: Object as () => TabProps, required: true },
    index: { type: Number, required: true },
  },
  setup(props) {
    const { atomics: { closeTab } } = useLayoutService()
    const { useTabContentWidget } = useWidgetService()
    const tabProps = useDragTab(props)

    const widget = useTabContentWidget(props.tab.id)
    const onClose = (e: MouseEvent) => {
      e.stopPropagation()
      // TODO: handle confirm
      closeTab({ path: props.path, index: props.index })
    }
    return () =>
      <Tab class="flex items-center group"
        index={props.index} noRipple buttonProps={tabProps}>
        {unref(widget.value?.title) ?? props.tab.title}
        <XMarkIcon
          class="ml-2 h-4 w-4 -mr-2 invisible transition group-hover:visible rounded dark:hover:bg-zinc-500 hover:bg-zinc-200"
          onClick={onClose}
        />
      </Tab>
  },
})

export const TabsLayout = defineComponent({
  name: "TabsLayout",
  props: {
    layout: { type: Object as () => TabsLayoutProps, required: true },
    path: { type: Array as () => LayoutPath, required: true },
  },
  setup(props) {
    const { lastFocusTab, atomics: { focusTab } } = useLayoutService()
    const currentTab = useUncontrolled(
      props.layout.currentTab,
      () => props.layout.currentTab,
      v => focusTab({ path: props.path, index: v })
    )
    const layoutProps = useDropLayout(props, ".TabsLayout > .Tabs")
    const focusIn = () => {
      lastFocusTab.value = props.layout.tabs[currentTab.value]?.id
    }
    watchEffect(focusIn)
    return () => {
      const { tabs } = props.layout
      const hasFocusing = tabs.some(x => x.id === lastFocusTab.value)
      return (
        <div class="TabsLayout grow flex flex-col" {...layoutProps}>
          <Tabs
            v-model={currentTab.value} color={!hasFocusing ? "none" : "primary"}
            class={["transition bg-white dark:bg-zinc-700 shadow whitespace-nowrap", !hasFocusing && "opacity-70"]}>
            {tabs.map((x, i) =>
              <DragTab key={x.id} layout={props.layout} tab={x} path={props.path} index={i} />)}
          </Tabs>
          <div class="flex-1 relative overflow-hidden" onFocusin={focusIn}>
            {/* TabWidgetView: absolute inset-0 */}
            {tabs.map((x, i) => <TabWidgetView key={x.id} tabId={x.id} show={currentTab.value === i} />)}
          </div>
        </div>
      )
    }
  },
})
