
declare global {
  interface CancellationToken {
    (): PromiseLike<void> | undefined | void
  }
}

export function never() {
  // The instance should not be held or reused
  return new Promise<never>(() => { /** noop */ })
}

export function idle() {
  return new Promise<void>(res => setTimeout(res as any, 0))
}

export function createCancellation(onIdle = true) {
  let isCanceled = false
  return {
    cancel() { isCanceled = true },
    token() {
      if (isCanceled) return never()
      return onIdle ? idle() : Promise.resolve()
    },
  }
}
