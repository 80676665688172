import { computed, reactive, unref, watchEffect } from "vue"
import { defineService } from "@maine-cat/common/service"

export interface WidgetState {
  /**
   * The tab id current widget is bound to.
   */
  bindingTab: string
  /**
   * widget URI
   */
  uri: string

  /**
   * Root element for portal target.
   */
  rootElement?: MaybeRef<HTMLElement>
  /**
   * Tab title.
   */
  title?: MaybeRef<string>

  /**
   * Type identifier for exposes to be well typed
   */
  type?: string
  /**
   * Widget exposes
   */
  exposes?: unknown
}

export const useWidgetService = defineService({
  id: "widget",
  setup() {
    const widgets = reactive<Record<string, WidgetState | undefined>>({})

    function useWidgetManagement(state: WidgetState) {
      watchEffect(cleanup => {
        const id = state.bindingTab
        widgets[id] = state
        cleanup(() => {
          delete widgets[id]
        })
      })
    }

    function useTabContentWidget(tabId: MaybeRef<string>) {
      return computed(() => widgets[unref(tabId)])
    }

    return { widgets, useWidgetManagement, useTabContentWidget }
  },
})
